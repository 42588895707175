// Buzzrr Setup Component
// Phone number selection and forwarding selection. 
import "./Setup.css";

import BuzzrrCallList from "../components/BuzzrrCallList";
import BuzzrrSetupPhoneNumber from "../components/BuzzrrSetupPhoneNumber";
import LoaderButton from "../components/LoaderButton";

import { useHistory } from "react-router-dom";

const DEBUG = false;

export default function Setup(props) {

  let history = useHistory();

  const userIDToken = props.authenticatedUserObject?.signInUserSession?.idToken?.jwtToken;
  const customerCallList = props.authenticatedUserData?.CustomerCallList?.BuzzrrCallList;
  const phoneNumberFriendly = props.authenticatedUserData?.CustomerData?.BuzzrrPhoneNumberData?.FriendlyNumber;
  const customerCountry = props.authenticatedUserData?.CustomerData?.BuzzrrPhoneNumberData?.Country;
  const phoneNumberSetupCompleteFlag = props.authenticatedUserData?.CustomerData?.BuzzrrPhoneNumberData?.SetupCompleted;

  if (phoneNumberSetupCompleteFlag) {
    return (
      <div className="p-5 lg:px-24 lg:py-10">
        <div className="font-header text-5xl font-extrabold tracking-tight text-gray-800">
          Call List Setup
        </div>
        <div className="font-sans font-medium text-xl text-gray-500">
          Enter the list of phone numbers you want <span className="font-logo font-black text-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> to
          call when someone dials your intercom.
        </div>
        <div className="flex flex-col md:w-full md:flex-row my-7 lg:my-5">
          <div className="flex-shrink-0 -mx-5 py-5 px-5 inline-block md:rounded-lg shadow font-medium text-lg bg-indigo-200 md:m-0 md:p-5 w-screen md:min-w-max md:max-w-lg">
            Your <span className="font-logo font-black text-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> call list is:
            <div className="pt-5">
              <BuzzrrCallList
                postUserData={props.postUserData}
                userIDToken={userIDToken}
                customerCountry={customerCountry}
                phoneNumberFriendly={phoneNumberFriendly}
                customerCallList={customerCallList}
              />
            </div>
          </div>
          <div className="flex-shrink text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
            <p>This is the list of phone numbers that will be called whenever your someone dials your building intercom.</p>
            <br />
            <p>You can always update your call list on the Dashboard page!</p>
          </div>
        </div>
        <div>
          <LoaderButton onClick={() => history.push('/dashboard')}>Done!</LoaderButton>
        </div>
      </div>

    );
  } else {
    return (
      <div className="p-5 lg:px-24 lg:py-10">
        <BuzzrrSetupPhoneNumber
          postUserData={props.postUserData}
          userIDToken={userIDToken}
          phoneNumberFriendly={phoneNumberFriendly}
        />
      </div>
    );
  }
}


