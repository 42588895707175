import "./Help.css";

import { useEffect } from "react";

import { useHistory } from "react-router-dom";

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';

export default function Help() {

  let history = useHistory();

  useEffect(() => {
    if (history.location.hash) {
      let hash = history.location.hash.substring(1);  // Remove the # in the front. 
      scrollTo(hash);
    } else {
      // window.scrollTo(0,0);
    }
  }, [history])

  function buzzrrLogo() {
    return (<span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>)
  }

  function scrollTo(elementId) {
    let element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView(); // Scroll to the DIV with this ID.
      window.scrollBy(0, -75);  // Adjust the scroll to account for the nav header.
    }
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function backToTop() {
    return (
      <button onClick={() => scrollToTop()} className="hover:underline block pt-3">
        <p className="text-sm">
          <FontAwesomeIcon className="text-indigo-600" icon={faArrowUp} />
          Back to Top
      </p>
      </button>
    )
  }


  return (
    <div className="p-5 lg:px-24 lg:py-10">
      <div className="font-header text-5xl font-extrabold tracking-tight text-gray-800">
        Frequently Asked Questions & Help
      </div>
      <div className="pt-10 pb-5">
        <p className="text-3xl font-extrabold">Questions?</p>
      </div>
      <div>
        <ul className="list-none list-inside leading-loose text-lg text-left">
          <li><button onClick={() => scrollTo('what-is-buzzrr')} className="text-left hover:underline">What is {buzzrrLogo()}?</button></li>
          <li><button onClick={() => scrollTo('how-does-buzzrr-work')} className="text-left hover:underline">How does {buzzrrLogo()} work?</button></li>
          <li><button onClick={() => scrollTo('what-is-a-buzzrr-phone-number')} className="text-left hover:underline">What is a {buzzrrLogo()} phone number?</button></li>
          <li><button onClick={() => scrollTo('what-do-i-do-with-my-buzzrr-phone-number')} className="text-left hover:underline">What do I do with my {buzzrrLogo()} phone number?</button></li>
          <li><button onClick={() => scrollTo('what-is-the-buzzrr-call-list')} className="text-left hover:underline">What is the {buzzrrLogo()} call list?</button></li>
          <li><button onClick={() => scrollTo('works-with-voicemail')} className="text-left hover:underline">How does {buzzrrLogo()} work with voicemail?</button></li>
          <li><button onClick={() => scrollTo('what-are-the-buzzrr-plans')} className="text-left hover:underline">What are the {buzzrrLogo()} plans?</button></li>
          <li><button onClick={() => scrollTo('how-do-i-cancel-buzzrr')} className="text-left hover:underline">How do I cancel {buzzrrLogo()}?</button></li>
          <li><button onClick={() => scrollTo('where-can-i-get-more-help')} className="text-left hover:underline">Where can I get more help?</button></li>
        </ul>
      </div>
      <div className="pt-10 pb-5">
        <p className="text-3xl font-extrabold">Answers.</p>
      </div>
      <div id="answers" className="flex flex-col space-y-5">
        <div id="what-is-buzzrr" className="">
          <p className="font-bold text-lg underline">What is {buzzrrLogo()}?</p>
          {buzzrrLogo()} is a cloud service that allows you to share your entry intercom with anyone you live with.
          <br/>When someone dials your entry intercom, {buzzrrLogo()} will call everyone who lives with you — like your partner or roommates. 
          <br/>Anyone can answer the entry intercom and let people in.
          <br/>And of course, you always have full control over who gets called.
          {backToTop()}
        </div>
        <div id="how-does-buzzrr-work" className="">
          <p className="font-bold text-lg underline">How does {buzzrrLogo()} work?</p>
          {buzzrrLogo()} provides you with your own dedicated {buzzrrLogo()} phone number for use with your entry intercom system. 
          <br/>This phone number must be programmed into your entry intercom system. 
          <br/>Typically your property management company to do this for you.
          <br/>Now when someone dials your intercom number, {buzzrrLogo()} will call everyone on your call list and anyone can answer and open the door.
          {backToTop()}
        </div>
        <div id="what-is-a-buzzrr-phone-number" className="">
          <p className="font-bold text-lg underline">What is a {buzzrrLogo()} phone number?</p>
          Your {buzzrrLogo()} phone number is assigned to you for your entry intercom system to call. 
          <br/>When your entry intercom system calls this number, {buzzrrLogo()} will call everyone on your call list and anyone can answer and open the door.
          {backToTop()}
        </div>
        <div id="what-do-i-do-with-my-buzzrr-phone-number" className="">
          <p className="font-bold text-lg underline">What do I do with my {buzzrrLogo()} phone number?</p>
          Your {buzzrrLogo()} phone number needs to be programmed into your entry intercom system. 
          <br/>Your property management can do this for you.
          {backToTop()}
        </div>
        <div id="what-is-the-buzzrr-call-list" className="">
          <p className="font-bold text-lg underline">What is the {buzzrrLogo()} call list?</p>
          Your {buzzrrLogo()} call list is the list of phone numbers that {buzzrrLogo()} will call anytime someone dials your entry intercom.
          {backToTop()}
        </div>
        <div id="works-with-voicemail" className="">
          <p className="font-bold text-lg underline">How does {buzzrrLogo()} work with voicemail?</p>
          When {buzzrrLogo()} calls everyone on your call list, {buzzrrLogo()} will ask anyone picking up the call to confirm by dialing '1'. 
          <br/>This prevents people who have no reception, or who send the call to voicemail from preventing other people from getting a chance to pick up the call.
          {backToTop()}
        </div>
        <div id="what-are-the-buzzrr-plans" className="">
          <p className="font-bold text-lg underline">What are the {buzzrrLogo()} plans?</p>
          At this time, {buzzrrLogo()} offers a single Standard plan. We're working on some other options as well!
          {backToTop()}
        </div>
        <div id="how-do-i-cancel-buzzrr" className="">
          <p className="font-bold text-lg underline">How do I cancel {buzzrrLogo()}?</p>
          To cancel your service, please click on the 'Cancel My Account' button at the bottom of the 'Account' page. 
          <br/>Your service will be cancelled immediately and you will be refunded for any unused service for the current billing month.  
          {backToTop()}
        </div>
        <div id="where-can-i-get-more-help" className="">
          <p className="font-bold text-lg underline">Where can I get more help?</p>
          Contact {buzzrrLogo()} by clicking on the chat button at the bottom right of any page on this website!
          {backToTop()}
        </div>
      </div>
    </div>
  );
}