import { useState, useEffect } from "react";
import "./BuzzrrSetupPhoneNumber.css";

import LoaderButton from "./LoaderButton";

const POST_BUZZRRPHONENUMBER_API_ROUTE = '/api/v1/customer/buzzrrphonenumber';
const POST_BUZZRRPHONENUMBER_CONFIRM_API_ROUTE = '/api/v1/customer/buzzrrphonenumber/confirm';

const DEBUG = false;


export default function BuzzrrSetupPhoneNumber(props) {
  // Component to Pick the Phone Number.
  // REQUIRED PROPS:
  // postUserData: App function for API GET for customer data.
  // userIDToken: The user JWT ID Token.
  // phoneNumberFriendly: The assigned phone number once it's assigned. 
  //                      This will be unassigned until this page is visited.
  //                      This page will trigger the number to be assigned; display it here.
  //                      Clicking next confirms the number and continues setup to next step.
  // 
  // Example:
  // <BuzzrrSetupPhoneNumber
  // fetchUserData={props.fetchUserData}
  // userIDToken={userIDToken}


  // State for any async loading.
  const [isLoading, setIsLoading] = useState(false);

  // State for any error messages.
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    // Call HTTP GET on Customer API to assign a phone number when this page first loads.
    // Get the new phone number and display it on this page for confirmation.
    setIsLoading(true);

    let response = props.postUserData(
      props.userIDToken,
      POST_BUZZRRPHONENUMBER_API_ROUTE,
      {}    // No body data needed for this API.
    );

    setIsLoading(false);

    if (response.error) {
      if (response.message) {
        if (DEBUG) console.log(response.message);
        setErrorMessage(response.message);
      }
    }

  }, [props]);

  async function handleSetupSubmit(event) {
    event.preventDefault();

    // Clear any active error message.
    setErrorMessage(null);

    // Function to handle submitting the signup form.
    setIsLoading(true);
    let response = props.postUserData(
      props.userIDToken,
      POST_BUZZRRPHONENUMBER_CONFIRM_API_ROUTE,
      {}    // No body data needed for this API.
    );

    if (response.error) {
      setIsLoading(false);

      if (response.message) {
        if (DEBUG) console.log(response.message);
        setErrorMessage(response.message);
      }
    }
  };

  function sendGtagEvent() {
    if (window.gtag) window.gtag("event", "purchase", {});
  }


  if (props.phoneNumberFriendly) {
    sendGtagEvent();

    return (
      <div className="max-w-lg">
        <div className="font-header text-5xl font-extrabold tracking-tight text-gray-800">
          Welcome to <span className="font-logo font-black text-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>
        </div>
        <div className="flex flex-col md:w-full md:flex-row my-5">
          <div className="flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg shadow font-medium text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg">
            Your <span className="font-logo font-black text-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> Phone Number is:
            <p className="font-sans text-3xl font-bold tracking-tight">{props.phoneNumberFriendly}</p>
          </div>
        </div>
        <div className="font-medium text-lg text-gray-500">
          <p>
            Give this phone number to your property management to program into your entry intercom system.
          </p>
          <p className="pt-2">You can find this number in the Dashboard at anytime.</p>
        </div>
        <div>
          {errorMessage}
        </div>
        <div className="my-5">
          <LoaderButton onClick={handleSetupSubmit}>
            Next
          </LoaderButton>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center m-auto">
        <div className="font-sans text-center">
          <span className="font-logo font-black text-black text-6xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>
          <p className="text-grey-900 text-xl">Loading...</p>
        </div>
        <div className="flex py-5 text-indigo-600 justify-center animate-pulse">
          <svg width="30" height="30" fill="currentColor" className="animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
            </path>
          </svg>
        </div>
      </div>
    )
  }
}