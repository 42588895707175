// Buzzrr Customer Component
// Customer Page

import "./Dashboard.css";

// React Router
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet";

import BuzzrrCallList from "../components/BuzzrrCallList";

const DEBUG = false;

export default function Dashboard(props) {

    //const firstName = props.authenticatedUserData?.CustomerData?.FirstName;
    //const lastName = props.authenticatedUserData?.CustomerData?.LastName;
    const twilioFriendlyNumber = props.authenticatedUserData?.CustomerData?.BuzzrrPhoneNumberData?.FriendlyNumber;
    const userIDToken = props.authenticatedUserObject?.signInUserSession?.idToken?.jwtToken;
    const customerCallList = props.authenticatedUserData?.CustomerCallList?.BuzzrrCallList;
    const phoneNumberFriendly = props.authenticatedUserData?.CustomerData?.BuzzrrPhoneNumberData?.FriendlyNumber;
    const customerCountry = props.authenticatedUserData?.CustomerData?.BuzzrrPhoneNumberData?.Country;


    return (
        <div className="p-5 lg:px-24 lg:py-10">
            <Helmet>
                <title>buzzrr - Dashboard</title>
            </Helmet>
            <div className="font-header text-5xl font-extrabold tracking-tight text-gray-800">
                Dashboard
            </div>
            <div className="font-medium text-xl text-gray-500">
                Control your <span className="font-logo font-black text-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> service,
                and which phone numbers to call.
            </div>
            <div className="flex flex-col md:w-full md:flex-row my-5">
                <div className="font-header flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-extrabold text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg">
                    Your <span className="font-logo font-black text-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> Phone Number is:
                    <p className="font-sans text-3xl font-bold tracking-tight">{twilioFriendlyNumber}</p>
                </div>
                <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                    Use this phone number with your building intercom system. <NavLink to="/help#what-is-a-buzzrr-phone-number" target="_blank" className="a-style">More help.</NavLink>
                </div>
            </div>
            <div className="flex flex-col md:w-full md:flex-row my-5">
                <div className="font-header flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-extrabold text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg">
                    Your <span className="font-logo font-black text-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> call list is:
                    <div className="pt-5 font-sans font-normal">
                        <BuzzrrCallList
                            postUserData={props.postUserData}
                            userIDToken={userIDToken}
                            customerCountry={customerCountry}
                            phoneNumberFriendly={phoneNumberFriendly}
                            customerCallList={customerCallList}
                        />
                    </div>
                </div>
                <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                    This is the list of phone numbers that will be called whenever someone dials your intercom. <NavLink to="/help#what-is-the-buzzrr-call-list" target="_blank" className="a-style">More help.</NavLink>
                </div>
            </div>
        </div>
    );
}