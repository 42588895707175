// Buzzrr Account Component
// Account Page



// BIG BUG!!!!! ALL STATE HOOKS SHOULD BE AT THE TOP LEVEL COMPONENT!!!!! 
// THE CALLING ORDER OF HOOKS MUST ALWAYS BE THE SAME!
// SHIT! THERE ARE SOME IN THE CHECKOUT COMPONENT.
// MAKE SURE TO ALWAYS RENDER THE CHECKOUT COMPONENT ON THIS PAGE
// TO MAKE SURE ALL HOOKS ARE ALWAYS CALLED IN THE SAME ORDER.




import { useState, useEffect } from "react";
import "./Account.css";
import poweredByStripe from "../images/powered_by_stripe.svg";

import { Auth } from "aws-amplify";

import { NavLink } from "react-router-dom";

import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';

import { Helmet } from "react-helmet";

import LoaderButton from "../components/LoaderButton";
import CreditCardLogo from "../components/CreditCardLogo";
import ConfirmOKCancelModal from "../components/ConfirmOKCancelModal";

import { motion } from "framer-motion"

import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

const DEBUG = false;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(STRIPE_KEY);

const GET_CUSTOMER_API_ROUTE = '/api/v1/customer';
const BILLING_API_URL = `${process.env.REACT_APP_URL}/api/v1/billing`;
const BILLING_CUSTOMER_API_URL = `${process.env.REACT_APP_URL}/api/v1/billing/customer`;
const CANCEL_CUSTOMER_API_URL = '/api/v1/customer/cancel';
const CHANGE_EMAIL_API_URL = '/api/v1/customer/change-email';
const CHANGE_EMAIL_CONFIRM_API_URL = '/api/v1/customer/change-email-confirm';


// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '1.2rem',
            '::placeholder': {
                color: '#aab7c4'
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

const CheckoutForm = (props) => {
    // Define CheckoutForm component. Must be used inside the React Stripe <Elements> component.

    const [error, setError] = useState(null);               // State for handling storing error messages to show users.
    const [email, setEmail] = useState('');                 // State for holding the email address.
    const [firstName, setFirstName] = useState('');                   // State for holding the customer's first name. 
    const [lastName, setLastName] = useState('');                   // State for holding the customer's name. 
    const [paymentSucceeded, setPaymentSucceeded] = useState(null);     // State for setting a successful payment.
    const [isLoading, setIsLoading] = useState(null);               // Loading state for disabling buttons.


    useEffect(() => {
        // Set user info from App user object.
        setFirstName(props.authenticatedUserObject.attributes.given_name);
        setLastName(props.authenticatedUserObject.attributes.family_name);
        setEmail(props.authenticatedUserObject.attributes.email);
    }, [
        props.authenticatedUserObject.attributes.email,
        props.authenticatedUserObject.attributes.given_name,
        props.authenticatedUserObject.attributes.family_name
    ]);

    const stripe = useStripe();         // Stripe React Hook
    const elements = useElements();     // Stripe React UI Elements Hook

    // Handle real-time validation errors from the card Element.
    const handleChange = (event) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    }

    function showAlert() {
        if (error || paymentSucceeded) {
            return (
                <div className="bg-red-200 border-red-600 text-red-600 my-2 p-2 rounded" role="alert">
                    <p className="font-sans font-medium tracking-tight">
                        {error}
                        {paymentSucceeded}
                    </p>
                </div>
            )
        }
    }

    // If there is a valid 'payment_intent.status' object check to confirm if additional secure card authentication steps are required.
    async function handleConfirmCardPayment(payment_intent) {
        if (payment_intent) {
            const { client_secret, status } = payment_intent;

            console.info(client_secret, status);

            // If card requires confirmation, then call the Stripe confirmCardPayment method. 
            if (status === 'requires_action' || 'requires_confirmation') {
                await stripe.confirmCardPayment(client_secret).then(function (result) {
                    if (result.error) {
                        if (DEBUG) if (DEBUG) console.log('subscription error ', result.error);
                        setError(result.error.message);
                        setIsLoading(null);
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc)
                    } else {
                        // Show a success message to your customer
                        if (DEBUG) if (DEBUG) console.log('congrats card confirmed!')
                        // setPaymentSucceeded("subscription success 2");

                        // Since, success, refresh user data to update App state.
                        props.fetchUserData(props.authenticatedUserObject.signInUserSession.idToken.jwtToken, GET_CUSTOMER_API_ROUTE);
                    }
                });
            } else {
                // No additional information was needed
                // Show a success message to your customer
                if (DEBUG) console.log('subscription success!')
                // setPaymentSucceeded("subscription success 1");

                // Since, success, refresh user data to update App state.
                props.fetchUserData(props.authenticatedUserObject.signInUserSession.idToken.jwtToken, GET_CUSTOMER_API_ROUTE);
            }
        }
    }

    // If there is a valid 'payment_intent.status' object check to confirm if additional secure card authentication steps are required.
    async function handleConfirmCardSetup(payment_intent) {
        if (payment_intent) {
            const { client_secret, status } = payment_intent;

            console.info('handleConfirmCard: client_secret, status:', client_secret, status);

            // If card requires confirmation, then call the Stripe confirmCardSetup method. 
            if (status === 'requires_action' || status === 'requires_confirmation') {
                await stripe.confirmCardSetup(client_secret).then(function (result) {
                    if (result.error) {
                        if (DEBUG) console.log('subscription error ', result.error);
                        setError(result.error.message);
                        setIsLoading(null);
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc)
                    } else {
                        // Show a success message to your customer
                        if (DEBUG) console.log('card update confirmed!')
                        // setPaymentSucceeded("update success 2");

                        // Since, success, refresh user data to update App state.
                        props.fetchUserData(props.authenticatedUserObject.signInUserSession.idToken.jwtToken, GET_CUSTOMER_API_ROUTE);
                    }
                });
            } else {

                // TODO!!! potential errors from the API need to be handled here.
                // The case is when the API has a statusCode error. 


                // No additional information was needed
                // Show a success message to your customer
                if (DEBUG) console.log('update success!')
                // setPaymentSucceeded("update success 1");

                // Since, success, refresh user data to update App state.
                props.fetchUserData(props.authenticatedUserObject.signInUserSession.idToken.jwtToken, GET_CUSTOMER_API_ROUTE);
            }
        }
    }

    // Handle form submission.
    const handleSubmit = async (event, method) => {
        setIsLoading(true);     // Disable Submit button.


        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        let paymentMethodResult;
        let requestBodyObject;

        if (method.toUpperCase() === 'POST') {
            // Handle POST request which is called for new Billing signup.

            // We don't want to let default form submission happen here,
            // which would refresh the page.
            event.preventDefault();
            setPaymentSucceeded(null);

            // Use Stripe Elements to gather credit card data. 
            paymentMethodResult = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name: firstName + ' ' + lastName,
                    email: email,
                },
                metadata: {
                    cognitoID: props.authenticatedUserObject.attributes.sub,
                },
            });

            // If the Stripe Element has invalid data; abort and reset loading state.
            if (paymentMethodResult.error) {
                // Inform the user if there was an error.
                setError(paymentMethodResult.error.message);
                setIsLoading(null);
                return;
            }

            requestBodyObject = {
                name: firstName + ' ' + lastName,
                email: email,
                payment_method: paymentMethodResult.paymentMethod.id,
            };

            // Send Payment method data to backend API to create new customer.
            await stripePaymentMethodHandler({
                paymentMethodResult: paymentMethodResult,
                requestBodyObject: requestBodyObject,
                jwtIdToken: props.authenticatedUserObject.signInUserSession.idToken.jwtToken,
                url: BILLING_API_URL,
                method: method,
            }).then(async stripeResponse => {

                if (DEBUG) console.log('POST: stripeResponse', stripeResponse);

                if (stripeResponse === "error") {
                    setPaymentSucceeded('Fetch Error');
                    setIsLoading(null);
                    return;
                };

                if (stripeResponse.object === 'subscription' && stripeResponse.status === 'trialing') {
                    // If the subscription status is 'trialing' then need to check the value of the 'pending_setup_intent' property.

                    // If a setup_intent object is listed, it needs to be confirmed. Pass the data back to the client to perform confirmation. 
                    if (stripeResponse.pending_setup_intent) {
                        // Get the 'pending_setup_intent' value from the Stripe subscription data.
                        const { pending_setup_intent } = stripeResponse;

                        // Check to see if stripe requires that the card needs to be confirmed and perform confirmation if required. 
                        await handleConfirmCardSetup(pending_setup_intent);
                    } else {
                        // Since no payment is success and no confirmation required, refresh user data to update App state.
                        props.fetchUserData(props.authenticatedUserObject.signInUserSession.idToken.jwtToken, GET_CUSTOMER_API_ROUTE);
                    }
                } else {
                    // For non trial subscriptions, handle here.
                    // Get the 'payment_intent' value from the Stripe subscription data.
                    const { latest_invoice } = stripeResponse;
                    const { payment_intent } = latest_invoice;

                    // Check to see if stripe requires that the card needs to be confirmed and perform confirmation if required. 
                    await handleConfirmCardPayment(payment_intent);
                }
            });
        } else if (method.toUpperCase() === 'PUT') {
            // Handle PUT call which is to handle billing card updates.

            // We don't want to let default form submission happen here,
            // which would refresh the page.
            event.preventDefault();
            setPaymentSucceeded(null);

            // Use Stripe Elements to gather credit card data. 
            paymentMethodResult = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name: firstName + ' ' + lastName,
                    email: email,
                },
                metadata: {
                    cognitoID: props.authenticatedUserObject.attributes.sub,
                },
            });

            if (DEBUG) console.log('PUT: paymentMethodResult', paymentMethodResult);

            // If the Stripe Element has invalid data; abort and reset loading state.
            if (paymentMethodResult.error) {
                // Inform the user if there was an error.
                setError(paymentMethodResult.error.message);
                setIsLoading(null);
                return;
            }

            requestBodyObject = {
                BillingCustomerID: props.authenticatedUserData.CustomerData?.BillingData?.CustomerID,
                payment_method: paymentMethodResult.paymentMethod.id,
            };

            // Send Payment method data to backend API to create new customer.
            await stripePaymentMethodHandler({
                paymentMethodResult: paymentMethodResult,
                requestBodyObject: requestBodyObject,
                jwtIdToken: props.authenticatedUserObject.signInUserSession.idToken.jwtToken,
                url: BILLING_API_URL,
                method: method,
            }).then(async setupIntent => {

                if (DEBUG) console.log('PUT: setupIntent', setupIntent);

                if (setupIntent === "error") {
                    setPaymentSucceeded('Fetch Error');
                    setIsLoading(null);
                    return;
                };

                // Get the 'payment_intent' value from the Stripe subscription data.
                const payment_intent = setupIntent;

                // Check to see if stripe requires that the card needs to be confirmed and perform confirmation if required. 
                await handleConfirmCardSetup(payment_intent);

                let requestBodyObject = {
                    BillingCustomerID: props.authenticatedUserData.CustomerData?.BillingData?.CustomerID,
                    payment_method: setupIntent.payment_method,
                };

                // HTTP PUT 'billing/customer' to update the default billing method on the customer. 
                await stripePaymentMethodHandler({
                    paymentMethodResult: {},      // Just need to use the fetch call.
                    requestBodyObject: requestBodyObject,
                    jwtIdToken: props.authenticatedUserObject.signInUserSession.idToken.jwtToken,
                    url: BILLING_CUSTOMER_API_URL,
                    method: 'PUT',
                }).then(() => {
                    // Since, success, refresh user data to update App state.
                    // alert('Your payment card data was successfully updated!');
                    props.fetchUserData(props.authenticatedUserObject.signInUserSession.idToken.jwtToken, GET_CUSTOMER_API_ROUTE);
                });
            });
        }
    };


    return (
        <>
            <form onSubmit={(event) => handleSubmit(event, props.method)} className="">
                <div className="max-w-lg">
                    {/* <div>
                        <label className="font-sans text-xs">First Name</label>
                        <input tabIndex="-1"
                            plaintext
                            readOnly
                            type="text"
                            placeholder="What's your first name?"
                            className="StripeElement appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                            focus:z-10 sm:text-sm"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                        />
                    </div>
                    <div>
                        <label className="font-sans text-xs">Last Name</label>
                        <input tabIndex="-1"
                            plaintext
                            readOnly
                            type="text"
                            placeholder="What's your last name?"
                            className="StripeElement appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                            focus:z-10 sm:text-sm"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)} />
                    </div>
                    <div>
                        <label className="font-sans text-xs">Email</label>
                        <input tabIndex="-1"
                            plaintext
                            readOnly
                            type="email"
                            placeholder="What's your email?"
                            className="StripeElement appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                        placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                        focus:z-10 sm:text-sm"
                            value={email} />
                    </div> */}
                    <div>
                        <label className="font-sans text-xs">Credit Card</label>
                        <div className="appearance-none bg-white rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                            focus:z-10 sm:text-sm">
                            <CardElement
                                id="card-element"
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={handleChange}

                            />
                        </div>
                    </div>
                    <div className="text-red-500 text-sm font-medium" role="alert">
                        {showAlert()}
                    </div>
                </div>
                <div className={`pt-2 ${props.componentAlignmentClass}`}>
                    <LoaderButton type="submit" isLoading={isLoading} >
                        {props.method === 'POST' ? 'Start Free Trial 🚀' : 'Update'}
                    </LoaderButton>
                    <br/>
                    <img alt="Powered by Stripe" className="inline-block pt-3" src={poweredByStripe} />
                </div>
            </form>
        </>
    );
}

// Call the Stripe Payment Method Handler backend API.
// API creates a customer and assigns the subscription.
// Returns a valid subscription object. 
async function stripePaymentMethodHandler({ paymentMethodResult, requestBodyObject, jwtIdToken, url, method }) {
    if (paymentMethodResult.error) {
        console.error('stripePaymentMethodHandler: paymentMethodResult.error');
        console.error(paymentMethodResult.error);
    } else {
        // Otherwise send paymentMethod.id to your server
        return fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtIdToken
            },
            body: JSON.stringify(requestBodyObject),
        }).then(response => {
            // Get fetch's returned promise object.
            if (DEBUG) console.log(response)
            if (!response.ok) return response.text().then(err => {
                throw new Error(err)
            });
            return response.json();     // Parse the response into json. 
        }).then(response => {
            // Return the Stripe subscription JSON object. 
            if (DEBUG) console.log('good')
            if (DEBUG) console.log(response);
            return response;
        }).catch(error => {
            if (DEBUG) console.log('paymentMethodHandler: unhandled exception!!', error);
            let errorObject = {
                error: true,
                message: error.message,
            }
            return errorObject;
            // TODO!: Find a way to refresh the user token and update the token state holder.
            // There is a edge case bug where if the user has been sitting on the page
            // and allows the ID token to expire then the billing API will return HTTP 401 unauthorized.

            // HTTP 500 is a API/Lambda error.
        });
    }
}


export default function Account(props) {

    // State holders for the password change form.
    const [isLoadingPassword, setIsLoadingPassword] = useState(null);   // Manage Password Change Loading State
    const [isLoadingCancel, setIsLoadingCancel] = useState(null);       // Manage Cancel Account Loading State
    const [changePasswordOldPassword, setChangePasswordOldPassword] = useState('');
    const [changePasswordNewPassword, setChangePasswordNewPassword] = useState('');
    const [changePasswordNewPasswordConfirm, setChangePasswordNewPasswordConfirm] = useState('');
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidPasswordConfirm, setInvalidPasswordConfirm] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState('');

    // State holders for name update form
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isLoadingNameChange, setIsLoadingNameChange] = useState(null);

    // State holder for new email address
    const [newEmail, setNewEmail] = useState('');
    const [isLoadingEmailChange, setIsLoadingEmailChange] = useState(null);
    const [changeEmailCodeState, setChangeEmailCodeState] = useState(false);
    const [verifyEmailCode, setVerifyEmailCode] = useState('')

    // Popper for Payment Page on Price
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip();

    useEffect(() => {
        Auth.currentAuthenticatedUser({
        }).then(user => {
            setFirstName(user.attributes.given_name);
            setLastName(user.attributes.family_name);
            setNewEmail(user.attributes.email);
        })
            .catch(err => {
                if (DEBUG) console.log(err);
            });
    }, [])

    useEffect(() => {
        // Manage Password Validation UI Elements

        const PASSWORD_REGEX_PATTERN = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/);
        const PASSWORD_REGEX_PATTERN_LENGTH = new RegExp(/^\S{8,99}$/);
        const PASSWORD_REGEX_PATTERN_UPPER = new RegExp(/(?=.*[A-Z])/);
        const PASSWORD_REGEX_PATTERN_LOWER = new RegExp(/(?=.*[a-z])/);
        const PASSWORD_REGEX_PATTERN_NUMBER = new RegExp(/(?=.*[0-9])/);
        const PASSWORD_REGEX_PATTERN_SPECIAL = new RegExp(/[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]+/);

        // Test New Password to REGEX
        if (!PASSWORD_REGEX_PATTERN.test(changePasswordNewPassword) && changePasswordNewPassword.length > 0) {
            setInvalidPassword(true);
        } else {
            setInvalidPassword(false);
        }

        // Check that the confirmation password equals the entered password.
        if (changePasswordNewPassword !== changePasswordNewPasswordConfirm && changePasswordNewPasswordConfirm.length > 0) {
            setInvalidPasswordConfirm(true);
        } else {
            setInvalidPasswordConfirm(false);
        }

        // Set password validation messages.
        if (changePasswordNewPassword.length > 0) {
            let isPasswordValidLength, isPasswordValidUpperCharacter, isPasswordValidLowerCharacter, isPasswordValidNumber, isPasswordValidSpecialCharacter;

            PASSWORD_REGEX_PATTERN_LENGTH.test(changePasswordNewPassword) ? isPasswordValidLength = '✅' : isPasswordValidLength = '❌'
            PASSWORD_REGEX_PATTERN_UPPER.test(changePasswordNewPassword) ? isPasswordValidUpperCharacter = '✅' : isPasswordValidUpperCharacter = '❌'
            PASSWORD_REGEX_PATTERN_LOWER.test(changePasswordNewPassword) ? isPasswordValidLowerCharacter = '✅' : isPasswordValidLowerCharacter = '❌'
            PASSWORD_REGEX_PATTERN_NUMBER.test(changePasswordNewPassword) ? isPasswordValidNumber = '✅' : isPasswordValidNumber = '❌'
            PASSWORD_REGEX_PATTERN_SPECIAL.test(changePasswordNewPassword) ? isPasswordValidSpecialCharacter = '✅' : isPasswordValidSpecialCharacter = '❌'

            setNewPasswordError(`Your new password must contain: \n\n ${isPasswordValidLength} at least 8 characters, 
            \n ${isPasswordValidUpperCharacter} at least 1 uppercase character, 
            \n ${isPasswordValidLowerCharacter} at least have 1 lowercase character, 
            \n ${isPasswordValidNumber} at least have 1 number,
            \n ${isPasswordValidSpecialCharacter} and at least 1 special character.`);
        } else {
            setNewPasswordError('');
        }

    }, [changePasswordNewPassword, changePasswordNewPasswordConfirm]);

    let customerCardBrand = props.authenticatedUserData?.CustomerData?.BillingData?.PaymentMethodDetailsBrand;
    let customerCardLast4 = props.authenticatedUserData?.CustomerData?.BillingData?.PaymentMethodDetailsLast4;
    let customerCardExpMonth = props.authenticatedUserData?.CustomerData?.BillingData?.PaymentMethodDetailsExpMonth;
    let customerCardExpYear = props.authenticatedUserData?.CustomerData?.BillingData?.PaymentMethodDetailsExpYear;

    function validatePasswordChangeForm() {

        return (
            changePasswordOldPassword
            && changePasswordNewPassword
            && changePasswordNewPasswordConfirm
            && !invalidPasswordConfirm
            && !invalidPassword
            && changePasswordOldPassword.length > 7
        );
    }

    const passwordHintAnimation = {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: "-15%" },
    }

    function validateNameChangeForm() {
        return (
            firstName
            && lastName
            && typeof firstName === 'string'
            && typeof lastName === 'string'
        )
    }

    function validateEmailChangeForm() {
        return (newEmail)
    }

    function handlePasswordChangeSubmit(event) {
        event.preventDefault();

        changePassword(changePasswordOldPassword, changePasswordNewPassword)
    }

    async function changePassword(oldPassword, newPassword) {
        setIsLoadingPassword(true);

        await Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                setIsLoadingPassword(null);
                if (DEBUG) console.log(data);
                // alert(data);
                alert('Password changed successfully!');
                window.location.reload();   // Reload the current page to trigger browser to save the password.
            })
            .catch(err => {
                setIsLoadingPassword(null);
                if (DEBUG) console.log(err);
                alert(err.message);
            });


    }

    async function handleNameChangeSubmit(event) {
        event.preventDefault();

        setIsLoadingNameChange(true);

        let user = await Auth.currentAuthenticatedUser();

        let result = await Auth.updateUserAttributes(user, {
            'given_name': firstName,
            'family_name': lastName
        });
        if (DEBUG) console.log(result);

        window.location.reload();
    }

    async function handleEmailChangeSubmit(event) {
        event.preventDefault();

        setIsLoadingEmailChange(true);

        if (props.authenticatedUserObject.attributes.email === newEmail) {
            alert(`${newEmail} is already your email address.`);
            setIsLoadingEmailChange(false);
            return
        }

        let response = await props.postData(
            props.authenticatedUserObject.signInUserSession.idToken.jwtToken,
            CHANGE_EMAIL_API_URL,
            {
                accessToken: props.authenticatedUserObject.signInUserSession.accessToken.jwtToken,
                newEmail: newEmail
            }
        );

        if (DEBUG) alert(JSON.stringify(response));
        console.log(response)

        if (response.message === 'email_pending_update') {
            setChangeEmailCodeState(true);
        } else {
            alert('Error updating email address. Please contact BUZZRR for assistance.');
        }

        setChangeEmailCodeState(true);

        setIsLoadingEmailChange(false);
    }

    async function handleVerifyEmailCode(event) {

        if (event) event.preventDefault();

        if (!verifyEmailCode) {
            alert('Missing verification code.');
            return
        }
        setIsLoadingEmailChange(true);

        let response = await props.postUserData(
            props.authenticatedUserObject.signInUserSession.idToken.jwtToken,
            CHANGE_EMAIL_CONFIRM_API_URL,
            {
                accessToken: props.authenticatedUserObject.signInUserSession.accessToken.jwtToken,
                newEmail: newEmail,
                code: verifyEmailCode,
            }
        );

        if (DEBUG) alert(JSON.stringify(response))

        if (response.message === 'email_update_verified') {
            alert('Email successfully updated.');
        } else {
            // alert ('Error updating email. Please contact BUZZRR for assistance.')
            if (response.error) {
                let message = JSON.parse(response.message);
                alert(message.message)
            }
        }

        setIsLoadingEmailChange(false);

        window.location.reload();
    }

    async function cancelAccount() {

        if (window.confirm('Please confirm that you would like to cancel your service.')) {
            if (window.confirm('Your service will be cancelled immediately.')) {
                setIsLoadingCancel(true);

                let response = await props.postUserData(
                    props.authenticatedUserObject.signInUserSession.idToken.jwtToken,
                    CANCEL_CUSTOMER_API_URL,
                    {
                        accessToken: props.authenticatedUserObject.signInUserSession.accessToken.jwtToken
                    }
                );

                if (response.message === 'cancel_success') {
                    alert('Your Account has been cancelled. Thank you!');
                    await props.signOut();
                } else {
                    alert('Error cancelling account. Please contact BUZZRR for assistance.');
                }
            }
        }
    }

    function getTrialEndDate(daysOfTrial) {
        // Generate Trial End Date.
        let trialEndDate = new Date(Date.now());                    // Get current date.
        trialEndDate.setDate(trialEndDate.getDate() + 31);          // Set trial end date to 31 days in the future.
        return trialEndDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }

    if (props.firstTimeUserSetupComplete) {
        return (
            <div className="p-5 lg:px-24 lg:py-10">
                <Helmet>
                    <title>buzzrr - Account</title>
                </Helmet>
                <div className="font-header text-5xl font-extrabold tracking-tight text-gray-800">
                    Your Account
                </div>
                <div className="font-medium text-xl text-gray-500">
                    Manage your service, billing and password information.
                </div>
                <div className="flex flex-col md:w-full md:flex-row my-5">
                    <div className="font-header flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-extrabold text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg">
                        Your current <span className="font-logo font-black text-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> plan is:
                        <p className="font-sans text-3xl font-bold tracking-tight">Standard ($4.99/month)</p>
                    </div>
                    <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                        This is your current subscription plan. <NavLink to="/help#what-are-the-buzzrr-plans" target="_blank" className="a-style">More help.</NavLink>
                    </div>
                </div>
                <div className="flex flex-col md:w-full md:flex-row my-5">
                    <div className="font-header flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-extrabold text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg">
                        Your current payment card information is:
                        <div className="font-sans font-normal pt-3">
                            <div className="billing-credit-card-box">
                                <div className="billing-creditcard-list">
                                    <ul>
                                        <li className="billing-credit-card-brand"><CreditCardLogo cardBrand={customerCardBrand} /></li>
                                        <li className="billing-credit-card-last4-label">Card Number</li>
                                        <li className="billing-credit-card-last4">... {customerCardLast4}</li>
                                        <li className="billing-credit-card-exp-label">Expiration</li>
                                        <li className="billing-credit-card-exp">{customerCardExpMonth} / {customerCardExpYear}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                        This is the current payment method information that we have on record.
                    </div>
                </div>
                <div className="flex flex-col md:w-full md:flex-row my-5">
                    <div className="font-header flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-extrabold text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg">
                        Update your payment card information:
                        <div className="font-sans font-normal">
                            <Elements stripe={stripePromise}>
                                <CheckoutForm
                                    authenticatedUserObject={props.authenticatedUserObject}
                                    authenticatedUserData={props.authenticatedUserData}
                                    fetchUserData={props.fetchUserData}
                                    componentAlignmentClass='text-left'
                                    method="PUT" />
                            </Elements>
                        </div>
                    </div>
                    <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                        If you want to change your payment card information, you can update it here.
                    </div>
                </div>
                <div className="flex flex-col md:w-full md:flex-row my-5">
                    <form className="font-header flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-extrabold text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg"
                        onSubmit={handlePasswordChangeSubmit}>
                        Change your password:
                        <div className="font-sans font-normal">
                            <div className="text-left">
                                <label htmlFor="password" className="font-sans text-xs">Enter your current password</label>
                                <input type="password" placeholder="Current password"
                                    value={changePasswordOldPassword} onChange={e => setChangePasswordOldPassword(e.target.value)} required
                                    className="appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm" />
                            </div>
                            <div className="text-left pt-2">
                                <label htmlFor="password" className="font-sans text-xs">Choose a new password</label>
                                <input type="password" placeholder="New password"
                                    value={changePasswordNewPassword} onChange={e => setChangePasswordNewPassword(e.target.value)} required
                                    className={`${invalidPassword ? "ring ring-red-600 ring-offset-2" : ""} 
                                            appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                            </div>
                            <div className="text-left">
                                <label htmlFor="password" className="font-sans text-xs">Confirm your new password</label>
                                <input type="password" placeholder="Confirm new password"
                                    value={changePasswordNewPasswordConfirm} onChange={e => setChangePasswordNewPasswordConfirm(e.target.value)} required
                                    className={`${invalidPasswordConfirm ? "ring ring-red-600 ring-offset-2" : ""} 
                                            appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                            </div>
                            <motion.div
                                animate={newPasswordError ? "open" : "closed"}
                                variants={passwordHintAnimation}
                                className="font-sans text-xs whitespace-pre-line leading-none py-3">
                                {newPasswordError}
                            </motion.div>
                            <div className="pt-2">
                                <LoaderButton isLoading={isLoadingPassword} disabled={!validatePasswordChangeForm()}
                                    type="submit">
                                    Confirm New Password
                                </LoaderButton>
                            </div>
                        </div>
                    </form>
                    <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                        If you want to change your password, you can change it here.
                    </div>
                </div>
                <div className="flex flex-col md:w-full md:flex-row my-5">
                    <form className="font-header flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-extrabold text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg"
                        onSubmit={handleNameChangeSubmit}>
                        Update your name:
                        <div className="font-sans font-normal">
                            <div className="text-left">
                                <label className="font-sans text-xs">First Name</label>
                                <input autoComplete="off"
                                    value={firstName} onChange={e => setFirstName(e.target.value)} required
                                    className={`appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                            </div>
                            <div className="text-left">
                                <label className="font-sans text-xs">Last Name</label>
                                <input autoComplete="off"
                                    value={lastName} onChange={e => setLastName(e.target.value)} required
                                    className={`appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                            </div>
                            <div className="pt-2">
                                <LoaderButton isLoading={isLoadingNameChange} disabled={!validateNameChangeForm()}
                                    type="submit">
                                    Update Name
                                </LoaderButton>
                            </div>
                        </div>
                    </form>
                    <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                        If you want to update your name, you can update it here.
                    </div>
                </div>
                <div className="flex flex-col md:w-full md:flex-row my-5">
                    <form className="font-header flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-extrabold text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg"
                        onSubmit={handleEmailChangeSubmit}>
                        Update your email address:
                        <div className="font-sans font-normal">
                            <div className="text-left">
                                <label htmlFor="email" className="font-sans text-xs">Email</label>
                                <input autoComplete="off" type="email"
                                    value={newEmail} onChange={e => setNewEmail(e.target.value)} required
                                    className={`appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                            </div>
                            <div className="pt-2">
                                <LoaderButton isLoading={isLoadingEmailChange} disabled={!validateEmailChangeForm()}
                                    type="submit">
                                    Update Email Address
                                </LoaderButton>
                            </div>
                        </div>
                    </form>
                    <ConfirmOKCancelModal
                        state={changeEmailCodeState}
                        cancelFunction={() => {
                            setChangeEmailCodeState(false);
                            setVerifyEmailCode('');
                        }}
                        confirmFunction={() => handleVerifyEmailCode()}
                        title="Please verify your new email address."
                        message={() => {
                            return (
                                <div>
                                    Enter the confirmation code sent to your new email address.
                                    <form onSubmit={handleVerifyEmailCode}>
                                        <input required
                                            value={verifyEmailCode} onChange={(e) => setVerifyEmailCode(e.target.value)}
                                            className={`appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                focus:z-10 sm:text-sm`}
                                            type="number" maxLength="10" />
                                    </form>
                                </div>
                            )
                        }} />
                    <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                        If you want to update your email address, you can update it here.
                    </div>
                </div>
                <div className="flex flex-col md:w-full md:flex-row my-5">
                    <div className="flex-none -mx-5 py-5 px-5 inline-block md:rounded-lg font-medium text-lg bg-indigo-200 md:m-auto md:p-5 w-screen md:min-w-max md:max-w-lg">
                        <LoaderButton isLoading={isLoadingCancel}
                            onClick={(e) => cancelAccount()}>
                            Cancel My Account
                        </LoaderButton>
                    </div>
                    <div className="flex-grow text-gray-500 font-medium text-sm md:px-5 py-1 lg:p-5">
                        If you cancel, your service will end immediately. We'll refund you the rest of the current month.<br />
                        Your phone number will be released and your user information will be deleted. <br />
                        Cancelling your account is permanent and your phone number will be lost.<br />
                        <NavLink to="/help#how-do-i-cancel-buzzrr" target="_blank" className="a-style">More help.</NavLink>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex flex-col p-1 md:p-10 text-center items-center">
                <Helmet>
                    <title>buzzrr - Payment</title>
                </Helmet>
                <div className="bg-white rounded-lg shadow p-5 lg:p-10 max-w-lg w-full">
                    <p className="font-sans text-3xl font-medium tracking-tight">Try <span className="font-logo font-black text-3xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span></p>
                    <p className="font-sans text-3xl font-medium tracking-tight">FREE for 1 month!</p>
                    <p className="tracking-tighter pt-2">Then $<span className="border-dotted border-b border-indigo-600" ref={setTriggerRef}>4.99</span> <span className="font-normal">per month.</span></p>
                    {visible && (
                        <div
                            ref={setTooltipRef}
                            {...getTooltipProps({ className: 'tooltip-container text-xs' })}
                        >
                            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                                You will be charged <br />in the currency of your <br />phone number location.
                        </div>
                    )}

                    <p>Cancel anytime, no questions asked.</p>
                    <div>
                        <div className="pt-10 lg:pt-5">
                            <div className="text-left">
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm
                                        authenticatedUserObject={props.authenticatedUserObject}
                                        fetchUserData={props.fetchUserData}
                                        componentAlignmentClass='text-center'
                                        method="POST" />
                                </Elements>
                                <p className="pt-12 lg:pt-5 text-center text-sm text-gray-500">If you don’t cancel your trial, you will be charged $4.99 per month, starting
                                <span> </span>
                                    {getTrialEndDate()}
                                . You will be charged in the currency of your phone number location.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
