import "./NotFound.css";

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadCry } from '@fortawesome/free-solid-svg-icons';

export default function NotFound() {
  return (
    <div className="font-mono text-center p-10 rounded-lg shadow bg-white w-full max-w-md mt-20 mx-auto">
      <p className="text-8xl text-yellow-400"><FontAwesomeIcon className="" icon={faSadCry} /></p>
      <p className="text-5xl font-bold text-red-500">HTTP 404</p>
      <p className="text-2xl">Oops! Page not found.</p>
    </div>
  );
} 