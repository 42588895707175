import { useState } from "react";
import "./BuzzrrCallList.css";

import LoaderButton from "./LoaderButton";
import ConfirmActionModal from "./ConfirmActionModal";

import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

const POST_BUZZRRCALLLIST_API_ROUTE = '/api/v1/customer/buzzrrcalllist';

// const DEBUG = false;


export default function SetupCallList(props) {
    // Component to Set up the calling list when Buzzrr number is called. 
    // REQUIRED PROPS:
    // postUserData: App function for API POST for customer data.
    // userIDToken: The user JWT ID Token.
    // customerCallList: The customer call list array from the database.
    // customerCountry: The country set for the Buzzrr phone number.
    // phoneNumberFriendly: The locally friendly formatted phone number.
    //
    // Example:
    // <SetupCallList
    // postUserData={props.postUserData}
    // userIDToken={userIDToken}
    // customerCountry={customerCountry}
    // phoneNumberFriendly={phoneNumberFriendly}
    // customerCallList={customerCallList}
    // />

    // State variables to hold a new customerCallList name and phone number entry. 
    const [newCustomerEntryName, setNewCustomerEntryName] = useState('');
    const [newCustomerEntryNumber, setNewCustomerEntryNumber] = useState('');

    function validateForwardNumbers() {
        // Function to validate the sign up form and enable the 'Add' button
        // Validate number is valid for the country selected.

        const newPhoneNumber = parsePhoneNumberFromString(newCustomerEntryNumber, props.customerCountry);
        return (newPhoneNumber && newCustomerEntryName) ? newPhoneNumber.isValid() : false;
    };

    function formatNumber(phoneNumber) {
        // If number is less than 5 then disable auto formatting to allow deleting brackets. 
        if (phoneNumber.length < 5) {
            setNewCustomerEntryNumber(phoneNumber);
        } else {
            let formattedNumber = new AsYouType(props.customerCountry).input(phoneNumber);
            setNewCustomerEntryNumber(formattedNumber);
        }
    }

    function printBuzzrrCallList(customerCallListArray) {
        // TODO !!!!! format this as a table? list? whats the best?
        if (Array.isArray(customerCallListArray)) {
            let tableData = customerCallListArray.map(function (item, index) {
                let key = 'BuzzrrCallList-' + index;
                return (
                    <tr key={key}>
                        <td className="px-3 py-2 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">
                                {item.name}
                            </div>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                                {item.nationalFormatPhoneNum}
                            </div>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-center text-sm font-medium">
                            <ConfirmActionModal
                                title={"Remove from Call List?"}
                                message={"Are you sure you want to remove \n" + item.name + " " + item.nationalFormatPhoneNum + " from the call list?"}
                                confirmText="Delete"
                                color="purple"
                                confirmAction={(e) => handleForwardNumberDelete(e, index)}
                            >
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </ConfirmActionModal>
                            {/* <button className="" onClick={renderDeleteConfirm(index)} >
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button> */}
                        </td>
                    </tr>
                )
            });

            let newEntryRow = (
                <tr key="BuzzrrCallListNewRow">
                    <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                            <input className="w-full p-0 text-sm rounded border-0 border-b-2 border-indigo-500 border-dotted focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                placeholder="Name"
                                type="text"
                                id="name"
                                name="name"
                                size="7"
                                value={newCustomerEntryName}
                                onChange={e => setNewCustomerEntryName(e.target.value.match(/[a-zA-Z0-9 ]{0,25}/))}
                                required />
                        </div>
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                            <input className="w-full p-0 text-sm rounded border-0 border-b-2 border-indigo-500 border-dotted focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                placeholder="Phone Number"
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                size="7"
                                onChange={e => formatNumber(e.target.value)}
                                value={newCustomerEntryNumber} required />
                        </div>
                    </td>
                    <td className="px-1 py-2 whitespace-nowrap text-center text-sm font-medium">
                        <LoaderButton size="sm" onClick={(e) => handleForwardNumberAdd(e)} className="" disabled={!validateForwardNumbers()}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </LoaderButton>
                    </td>
                </tr>
            );

            let finalTable = ((tableData) => {
                return (
                    <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-indigo-50">
                                            <tr>
                                                <th scope="col" className="w-5/12 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Name
                                                    </th>
                                                <th scope="col" className="w-5/12 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Phone Number
                                                    </th>
                                                <th scope="col" className="relative px-3 py-2">
                                                    <span className="sr-only">Delete</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {tableData}
                                            {(customerCallListArray.length < 5) ? newEntryRow : ''}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })(tableData);

            return finalTable;
        }
    }


    async function handleForwardNumberAdd(event) {
        // Handle adding new phone number entries to the array.

        event.preventDefault();

        // Check to see if this is a duplicate number.
        if (Array.isArray(props.customerCallList)) {
            let isDuplicateNumber = false;
            props.customerCallList.map(value => {
                if (value.nationalFormatPhoneNum === newCustomerEntryNumber) isDuplicateNumber = true
                return true     // unused return call
            });
            
            if (isDuplicateNumber) {
                alert (`${newCustomerEntryNumber} is already in your call list.`)
                setNewCustomerEntryNumber('');
                return
            }
        }

        // Format data for POST to Customer API.
        let newNameNumberCountryToSubmit = {
            BuzzrrCallList: props.customerCallList.concat({
                name: newCustomerEntryName,
                phoneNum: newCustomerEntryNumber,
            }),
            Country: props.customerCountry
        };

        // Reset new entry fields. 
        setNewCustomerEntryName('');
        setNewCustomerEntryNumber('');

        await handleNewCustomerCallListPost(newNameNumberCountryToSubmit);
    }

    async function handleForwardNumberDelete(event, index) {
        // Handle adding new phone number entries to the array.

        event.preventDefault();

        // Return a new array without the item being deleted. 
        let newCustomerCallList = props.customerCallList.filter((_, filterIndex) => index !== filterIndex);

        // Format data for POST to Customer API.
        let newNameNumberCountryToSubmit = {
            BuzzrrCallList: newCustomerCallList,
            Country: props.customerCountry
        };

        await handleNewCustomerCallListPost(newNameNumberCountryToSubmit);
    }


    async function handleNewCustomerCallListPost(newNameNumberCountryToSubmit) {
        // Handle submitting new array of phone numbers to the Customer API.
        // Call postUserData function from the main App to POST to customer API and update App state. 
        await props.postUserData(
            props.userIDToken,
            POST_BUZZRRCALLLIST_API_ROUTE,
            newNameNumberCountryToSubmit
        );
    };

    return (
        <div className="BuzzrrCallList">
            <div className="BuzzrrCallList-table">
                {printBuzzrrCallList(props.customerCallList)}
            </div>
        </div>
    );
}