// Buzzrr Login Page

import { useState, useEffect } from 'react';
import "./Login.css";

import LoaderButton from "../components/LoaderButton";

// React Router History Hook
import { useHistory } from "react-router-dom";

// AWS Amplify, Auth for handling Cognito authentication.
import { Auth } from 'aws-amplify';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const DEBUG = false;

export default function Login(props) {

    let history = useHistory();

    const [username, setUsername] = useState('');               // State Hook for Username
    const [password, setPassword] = useState('');               // State Hook for Password

    // State Hook for Sign In Button Text and Loading Spinner.
    const [isLoading, setIsLoading] = useState(false);

    // State for handling the sign in error messages.
    const [signInErrorMessage, setSignInErrorMessage] = useState(null);

    // State for triggering page state for forgot password page.
    const [pageState, setPageState] = useState('login');

    // State for holding the account verification code when resetting password.
    const [accountVerificationCode, setAccountVerificationCode] = useState('');

    // State for holding error message during password reset.
    const [passwordResetResult, setPasswordResetResult] = useState(null);


    // Password reset states
    const [changePasswordNewPassword, setChangePasswordNewPassword] = useState('');
    const [changePasswordNewPasswordConfirm, setChangePasswordNewPasswordConfirm] = useState('');
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidPasswordConfirm, setInvalidPasswordConfirm] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState('');


    useEffect(() => {
        // Manage Password Validation UI Elements

        // eslint-disable-next-line
        const PASSWORD_REGEX_PATTERN = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/);
        const PASSWORD_REGEX_PATTERN_LENGTH = new RegExp(/^\S{8,99}$/);
        const PASSWORD_REGEX_PATTERN_UPPER = new RegExp(/(?=.*[A-Z])/);
        const PASSWORD_REGEX_PATTERN_LOWER = new RegExp(/(?=.*[a-z])/);
        // eslint-disable-next-line
        const PASSWORD_REGEX_PATTERN_NUMBER = new RegExp(/(?=.*[0-9])/);
        // eslint-disable-next-line
        const PASSWORD_REGEX_PATTERN_SPECIAL = new RegExp(/(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])/);

        // Test New Password to REGEX
        if (!PASSWORD_REGEX_PATTERN.test(changePasswordNewPassword) && changePasswordNewPassword.length > 0) {
            setInvalidPassword(true);
        } else {
            setInvalidPassword(false);
        }

        // Check that the confirmation password equals the entered password.
        if (changePasswordNewPassword !== changePasswordNewPasswordConfirm && changePasswordNewPasswordConfirm.length > 0) {
            setInvalidPasswordConfirm(true);
        } else {
            setInvalidPasswordConfirm(false);
        }

        // Set password validation messages.
        if (changePasswordNewPassword.length > 0) {
            let isPasswordValidLength, isPasswordValidUpperCharacter, isPasswordValidLowerCharacter, isPasswordValidNumber, isPasswordValidSpecialCharacter;

            PASSWORD_REGEX_PATTERN_LENGTH.test(changePasswordNewPassword) ? isPasswordValidLength = '✅' : isPasswordValidLength = '❌'
            PASSWORD_REGEX_PATTERN_UPPER.test(changePasswordNewPassword) ? isPasswordValidUpperCharacter = '✅' : isPasswordValidUpperCharacter = '❌'
            PASSWORD_REGEX_PATTERN_LOWER.test(changePasswordNewPassword) ? isPasswordValidLowerCharacter = '✅' : isPasswordValidLowerCharacter = '❌'
            PASSWORD_REGEX_PATTERN_NUMBER.test(changePasswordNewPassword) ? isPasswordValidNumber = '✅' : isPasswordValidNumber = '❌'
            PASSWORD_REGEX_PATTERN_SPECIAL.test(changePasswordNewPassword) ? isPasswordValidSpecialCharacter = '✅' : isPasswordValidSpecialCharacter = '❌'

            setNewPasswordError(`Your new password must contain: \n\n ${isPasswordValidLength} at least 8 characters, 
            \n ${isPasswordValidUpperCharacter} at least 1 uppercase character, 
            \n ${isPasswordValidLowerCharacter} at least have 1 lowercase character, 
            \n ${isPasswordValidNumber} at least have 1 number,
            \n ${isPasswordValidSpecialCharacter} and at least 1 special character.`);
        } else {
            setNewPasswordError('');
        }

    }, [changePasswordNewPassword, changePasswordNewPasswordConfirm]);

    async function signIn() {
        // Function to Sign in a user using AWS Cognito
        // Requires that the username and password state are correctly set.

        setSignInErrorMessage(null);    // Clear any error message. 

        // Catch case where there is no username or password entered in the login box. 
        if (username === null || password === null) {
            setSignInErrorMessage('Missing username or password.');     // Set error message.
            return;
        }
        setIsLoading(true)      // Set spinner on Login button
        await Auth.signIn(      // Attempt login to Cognito using AWS Amplify
            username,
            password
        ).then(user => {                                // If login is successful then set <App> user state.
            setIsLoading(false);                        // Set the Login button back to normal. Remove the spinner.
            props.setAuthenticatedUserObject(user);     // Handle 'setAuthenticatedUserObject' prop. Lift user object up to calling component. 
            // This should be the main App. 
            // if (DEBUG) console.log(user);                       // DEBUG: Show the current user in logs.

            // Since the App container is handling routing depending on authentication state, this can be commented out. 
            //history.push("/");                          // React Router History Hook - Redirect to '/' after login.
        }).catch(err => {
            if (DEBUG) console.log(err);
            setIsLoading(false);                        // If there is an error remove the spinner from the Login button. 
            setSignInErrorMessage(err.message);         // Show the login error message. // ??FUTURE SEND THESE TO CLOUDWATCH??
            handleSignInErrors(err.code);
        })
    }

    function validateLoginForm() {
        return username.length > 0 && password.length > 0;
    }

    function validatePasswordResetForm() {
        return username.length > 0;
    }

    function validatePasswordResetVerificationForm() {
        return accountVerificationCode.length > 0
            && (changePasswordNewPassword === changePasswordNewPasswordConfirm)
            && changePasswordNewPassword.length > 0
            && changePasswordNewPasswordConfirm.length > 0
            && !invalidPasswordConfirm
            && !invalidPassword;
    }

    function changePageState(state) {
        // Reset state when moving beween page states.

        setPageState(state);
        setPassword('');
        setChangePasswordNewPassword('');
        setChangePasswordNewPasswordConfirm('');
        setSignInErrorMessage(null);
        setAccountVerificationCode('');
        setPasswordResetResult(null);

        if (state !== 'passwordVerify') setUsername('')

    }

    async function handleLoginSubmit(e) {
        // Function to handle login form submit action. 
        e.preventDefault();     // Prevent default action of submitting form and refreshing page. Let signIn() handle in React.
        await signIn();
    }

    async function handleSignInErrors(error) {
        // Function to handle Sign in errors. 

        if (error === 'UserNotConfirmedException') {        // Handle 'UserNotConfirmedException'
            history.push({
                pathname: '/signup',                        // Redirect to signup page
                state: { confirmPass: password, confirmEmail: username }            // Pass email and password in location state. 
            })
        }
    }

    async function signOut() {
        // Function to Sign out a user using AWS Cognito

        await Auth.signOut()
            .then(data => {                             // Clear the current user state and logout.
                props.setAuthenticatedUserObject(null);     // Handle 'setAuthenticatedUserObject' prop. Clear user token from main App component. 
                // if (DEBUG) console.log(data)                    // DEBUG: Show the result of signout in logs.
            })
            .catch(err => {
                if (DEBUG) console.log(err)
            })             // ??FUTURE SEND THESE TO CLOUDWATCH??
    }

    async function handlePasswordResetSubmit(event) {
        // Function to send password reset to user.

        event.preventDefault();

        await Auth.forgotPassword(username)
            .then(data => {
                if (DEBUG) console.log('password reset ok', data);
                changePageState('passwordVerify');
            })
            .catch(err => {
                if (DEBUG) console.log('password reset err', err);
                setPasswordResetResult(err.message);
            });


    }


    async function handlePasswordResetVerificationSubmit(event) {
        event.preventDefault();

        await Auth.forgotPasswordSubmit(username, accountVerificationCode, changePasswordNewPassword)
            .then(data => {
                if (DEBUG) console.log('password reset verify ok', data)
                alert('Password successfully changed.');
                changePageState('login');
                history.push('/login');         // Force page refresh so that the browser will ask to save the password.
            })
            .catch(err => {
                if (DEBUG) console.log('password reset verify err', err)
                setPasswordResetResult(err.message);
            });
    }

    function showAlertIfLoginError() {
        // Function to Show Error Message

        if (signInErrorMessage === null) {
            return;
        } else {
            // AWS Amplify returns error 'CUSTOM_AUTH is not enabled for the client.' when only a username is passed. 
            // Change this error to be more user friendly. 
            if (signInErrorMessage.includes('CUSTOM_AUTH')) setSignInErrorMessage('Missing username or password.');
            return (
                <div className="bg-red-200 border-red-600 text-red-600 mt-5 p-2 rounded" role="alert">
                    <p className="font-sans font-medium tracking-tight">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Uh-oh!<br />
                        {signInErrorMessage}
                    </p>
                </div>
            )
        }
    }

    function showPasswordResetResult() {
        if (passwordResetResult === null) {
            return;
        } else {
            return (
                <div className="bg-red-200 border-red-600 text-red-600 mt-5 p-2 rounded" role="alert">
                    <p className="font-sans font-medium tracking-tight">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Uh-oh!<br />
                        {passwordResetResult}
                    </p>
                </div>
            )
        }
    }

    if (pageState === 'forgotPassword') {
        return (
            <div className="flex flex-col p-1 md:p-10 text-center items-center">
                <form className="bg-white rounded-lg shadow p-5 lg:p-10 max-w-lg w-full" onSubmit={handlePasswordResetSubmit}>
                    <span className="font-logo font-black text-3xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>
                    <p className="font-sans text-2xl font-medium tracking-tight pb-5">Forgot your password?</p>
                    <div className="rounded-md shadow -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">Email address</label>
                            <input id="email-address" autoFocus name="email" type="email" autoComplete="email"
                                value={username} onChange={e => setUsername(e.target.value)}
                                className="appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                focus:z-10 sm:text-sm"
                                placeholder="Email address" />
                        </div>
                    </div>
                    <div className="pt-5">
                        <LoaderButton isLoading={isLoading} disabled={!validatePasswordResetForm()} type="submit">Reset Password</LoaderButton>
                    </div>
                    {showPasswordResetResult()}
                </form>
                <div className="p-1 text-left">
                    <button onClick={() => changePageState('login')} className="text-sm a-style">
                        {'< Back to Sign Page'}
                    </button>
                </div>
            </div>
        );
    } else if (pageState === 'passwordVerify') {
        return (
            <div className="flex flex-col p-1 md:p-10 text-center items-center">
                <form className="bg-white rounded-lg shadow p-5 lg:p-10 max-w-lg w-full" onSubmit={handlePasswordResetVerificationSubmit}>
                    <p className="font-logo font-black text-3xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</p>
                    <p className="font-sans text-2xl font-medium tracking-tight pb-5">Set a new password.</p>
                    <p className="pb-5 font-medium">Please check your email address for a verification code. Enter the verification code and your new password below.</p>
                    <div className="rounded-md -space-y-px">
                        <div className="text-left">
                            <label htmlFor="password" className="font-sans text-xs">Enter the account verification code.</label>
                            <input type="tel" placeholder="Verification Code"
                                value={accountVerificationCode} onChange={e => setAccountVerificationCode(e.target.value)} required
                                className="appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm" />
                        </div>
                        <div className="hidden text-left pt-5">
                            {/* Hidden Email Field to trigger browser password management features to save both email and password */}
                            <label htmlFor="email-address" className="sr-only">Email address</label>
                            <input id="email-address" name="email" type="email" autoComplete="email"
                                value={username} readOnly
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm"
                                placeholder="Email address" />
                        </div>
                        <div className="text-left pt-5">
                            <label htmlFor="password" className="font-sans text-xs">Choose a new password</label>
                            <input type="password" placeholder="New password"
                                value={changePasswordNewPassword} onChange={e => setChangePasswordNewPassword(e.target.value)} required
                                className={`${invalidPassword ? "ring ring-red-600 ring-offset-2" : ""} 
                                            appearance-none rounded shadow relative block w-full px-3 py-2 mb-3 lg:mb-4 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                        </div>
                        <div className="text-left">
                            <label htmlFor="password" className="font-sans text-xs">Confirm your new password</label>
                            <input type="password" placeholder="Confirm password"
                                value={changePasswordNewPasswordConfirm} onChange={e => setChangePasswordNewPasswordConfirm(e.target.value)} required
                                className={`${invalidPasswordConfirm ? "ring ring-red-600 ring-offset-2" : ""} 
                                            appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                        </div>
                    </div>
                    <div className="font-sans text-left text-xs whitespace-pre-line leading-none py-3">
                        {newPasswordError}
                    </div>
                    <div className="pt-5">
                        <LoaderButton isLoading={isLoading} disabled={!validatePasswordResetVerificationForm()} type="submit">Confirm New Password</LoaderButton>
                    </div>
                    {showPasswordResetResult()}
                </form>
                <div className="p-1 text-left">
                    <button onClick={() => changePageState('login')} className="text-sm a-style">
                        {'< Back to Sign Page'}
                    </button>
                </div>
            </div>
        );
    } else if (props.authenticatedUserObject === null && pageState === 'login') {
        return (
            <div className="flex flex-col p-1 md:p-10 text-center items-center">
                <form className="bg-white rounded-lg shadow p-5 lg:p-10 max-w-lg w-full" onSubmit={handleLoginSubmit}>
                    <p className="font-logo font-black text-3xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</p>
                    <p className="font-sans text-2xl font-medium tracking-tight pb-5">Sign in to your account</p>
                    <div className="rounded-md shadow -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">Email address</label>
                            <input id="email-address" autoFocus name="email" type="email" autoComplete="email"
                                value={username} onChange={e => setUsername(e.target.value)}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm"
                                placeholder="Email address" />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input id="password" name="password" type="password" autoComplete="current-password"
                                value={password} onChange={e => setPassword(e.target.value)}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm"
                                placeholder="Password" />
                        </div>
                    </div>
                    <div className="pt-5">
                        <LoaderButton isLoading={isLoading} disabled={!validateLoginForm()} type="submit">Sign In</LoaderButton>
                    </div>
                    {showAlertIfLoginError()}
                </form>
                <div className="p-1 text-left">
                    <button onClick={() => changePageState('forgotPassword')} className="text-sm a-style">
                        Forgot your password?
                    </button>
                </div>
            </div>
        );
    } else {
        return (
            <div className="text-center p-10">
                <LoaderButton onClick={signOut}>Sign Out</LoaderButton>
            </div>
        )
    }

}