// Use this hook to update Google Analytics gtag with new page paths on single page application websites. 

import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'


export const useTracking = (trackingId) => {
  const { listen } = useHistory()

  useEffect(() => {

    const unlisten = listen((location) => {

      // Check to see if gtag was loaded in index.html. 
      if (!window.gtag) return

      // Check if there is a valid tracking id passed in.
      if (!trackingId) {
        console.log(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
        )
        return
      }

      // Tell gtag to send a page_path event.
      window.gtag('config', trackingId, { page_path: location.pathname })
    })

    // React Router useHistory listen method returns a method to remove the event listener. 
    // Pass this as a return to the useEffect hook for clean up.
    return unlisten
  }, [trackingId, listen])
}