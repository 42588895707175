
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcAmex, faCcDinersClub, faCcDiscover, faCcJcb } from '@fortawesome/free-brands-svg-icons';

export default function CreditCardLogo({
  cardBrand = "",
  ...props
}) {
    if (cardBrand.toUpperCase() === 'VISA') {
        return (
            <FontAwesomeIcon icon={faCcVisa} />
        )
    } else if (cardBrand.toUpperCase() === 'MASTERCARD') {
        return (
            <FontAwesomeIcon icon={faCcMastercard} />
        )
    } else if (cardBrand.toUpperCase() === 'AMEX') {
        return (
            <FontAwesomeIcon icon={faCcAmex} />
        ) 
    } else if (cardBrand.toUpperCase() === 'DINERS CLUB') {
        return (
            <FontAwesomeIcon icon={faCcDinersClub} />
        ) 
    } else if (cardBrand.toUpperCase() === 'DISCOVER') {
        return (
            <FontAwesomeIcon icon={faCcDiscover} />
        ) 
    } else if (cardBrand.toUpperCase() === 'JCB') {
        return (
            <FontAwesomeIcon icon={faCcJcb} />
        ) 
    } else {
        return cardBrand.toUpperCase();
    }
}