import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// A component to scroll the window to the top when the pathname changes. 
// Render this compoenent at the top of your app, but below Router
// For Example: 
// function App() {
//   return (
//     <Router>
//       <ScrollToTop />
//       <App />
//     </Router>
//   );
// }
// https://reactrouter.com/web/guides/scroll-restoration

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}