// Buzzrr Home Component
// Home page

import { useState } from "react";
import "./Main.css";

import { NavLink } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";

import { motion } from "framer-motion"

import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import desktopHero from "../images/desktop-background.svg";
import mobileHero from "../images/mobile-background.svg";


// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUsers,
  faTruck,
  faConciergeBell,
  faInfinity,
  faGamepad,
  faGlobeAmericas,
  faEllipsisH,
  faGlassCheers,
  faVolumeMute,
  faUserPlus,
  faBuilding,
  faAddressBook,
  faPhoneVolume,
  faInfoCircle,
  faPlusSquare,
  faMinusSquare
} from '@fortawesome/free-solid-svg-icons';

const DEBUG = false;

const animationVariants = {
  buzzrrMainContainer: {
    animate: {
      transition: {
        staggerChildren: 0.25
      }
    }
  },
  buzzrrHeroContainerSync: {
    animate: {
      transition: {
        staggerChildren: 0
      }
    }
  },
  buzzrrHeroContainer: {
    animate: {
      transition: {
        staggerChildren: 0.25
      }
    }
  },
  buzzrrHeroZoom: {
    initial: {
      scale: 0.75, opacity: 0
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.25
      }
    },
  },
  buzzrrHeroFade: {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1
      }
    },
  },
  featureCards: {
    initial: {
      opacity: 0, y: -25
    },
    animate: {
      opacity: 1, y: 0
    }
  },
  featureCardsContainer: {
    animate: {
      transition: {
        staggerChildren: 0.075
      }
    }
  },
  buttonClicked: {
    rotate: 180,
    transform: {
      transformOrigin: 'center'
    }
  },
  buttonNotClicked: {
    rotate: 0
  }
}

const buttonStyle = {
  transformOrigin: '34% 51%'
}


export default function Main() {

  const [displayCanadaCities, setDisplayCanadaCities] = useState(false);
  const [displayUsaCities, setDisplayUsaCities] = useState(false);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  return (
    <motion.div initial="initial" animate="animate" variants={animationVariants.buzzrrMainContainer}
      className="lg:grid lg:grid-cols-3">
      <motion.div initial="initial" animate="animate" variants={animationVariants.buzzrrHeroContainerSync}
        className="col-span-full">
        <motion.div initial="initial" animate="animate" variants={animationVariants.buzzrrHeroContainer}>
          <div className="hidden lg:block">
            <div className="grid grid-rows-6 grid-cols-10 bg-indigo-50">
              <div className="col-start-6 col-end-11 row-start-1 row-end-7">
                <img src={desktopHero} alt="logo" />
              </div>
              <div className="row-start-1 row-end-5 col-start-1 col-end-7 pt-10 pl-24">
                <motion.p variants={animationVariants.buzzrrHeroFade}
                  className="font-header font-extrabold lg:text-5xl xl:text-7xl tracking-tighter py-1
              bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-indigo-600 to-purple-700">
                  Share your apartment intercom and more!
                </motion.p>
                <motion.p variants={animationVariants.buzzrrHeroFade}
                  className="pt-3 text-2xl text-gray-700 font-medium tracking-tight leading-tight	">
                  Allow everyone you live with to use the entry intercom!
              <br />Have your intercom system call everyone at the same time.
              {/* <br />Use the cloud to connect everyone to your/the intercom */}
                </motion.p>
              </div>
              <div className="row-start-5 row-end-7 col-start-1 col-end-6">
                <motion.div className="flex pl-24 items-center h-full" variants={animationVariants.buzzrrHeroFade}>
                  <div>
                    <NavLink to="/signup">
                      <LoaderButton size="hero"><span className="lg:text-2xl xl:text-3xl">Sign Up 🎉</span></LoaderButton>
                    </NavLink>
                  </div>
                  <div className="flex content-center text-center mt-1 lg:mt-0 mx-auto lg:mx-5 text-base lg:text-xl tracking-tighter font-bold">
                    <div className="lg:text-2xl xl:text-3xl font-header m-auto bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 via-pink-500 to-red-600">
                      1 month free trial!
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div initial="initial" animate="animate" variants={animationVariants.buzzrrHeroContainer}>
          <div className="lg:hidden p-5">
            {/* <motion.h1 variants={animationVariants.buzzrrHeroZoom}
          className="font-logo text-8xl text-red-500 inline-block	px-2">BUZZRR</motion.h1> */}
            <motion.p variants={animationVariants.buzzrrHeroFade}
              className="font-header font-extrabold text-5xl tracking-tighter py-1
          bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-indigo-600 to-purple-700">
              Share your apartment intercom and more!
            </motion.p>
            <motion.p variants={animationVariants.buzzrrHeroFade}
              className="pt-3 text-2xl text-gray-700 font-medium tracking-tight leading-tight	">
              Allow everyone you live with to use the entry intercom!
          <br />Have your intercom system call everyone at the same time.
          {/* <br />Use the cloud to connect everyone to your/the intercom */}
            </motion.p>
            <motion.div variants={animationVariants.buzzrrHeroFade}
              className="flex flex-col lg:flex-row pt-5 text-center lg:text-left">
              <div>
                <NavLink to="/signup">
                  <LoaderButton size="hero">Sign Up 🎉</LoaderButton>
                </NavLink>
              </div>
              <div className="flex content-center text-center mt-1 mx-auto text-base tracking-tighter font-bold">
                <div className="font-header m-auto bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 via-pink-500 to-red-600">
                  1 month free trial!
            </div>
              </div>
            </motion.div>
          </div>
          <div className="lg:hidden">
            <img src={mobileHero} className="w-full" alt="logo" />
          </div>
        </motion.div>
      </motion.div>
      <motion.div variants={animationVariants.featureCardsContainer}
        className="col-span-3 text-left bg-white pt-10 pb-10 px-5 lg:px-24">
        <motion.p variants={animationVariants.featureCards}
          className="font-header text-4xl font-extrabold tracking-tighter pb-10 px-5">
          Do you share an apartment or condominium with others?
          <br />Does your intercom system only call one person?
        </motion.p>
        <div className="grid gap-4 grid-rows-none lg:grid-cols-3">
          <motion.div variants={animationVariants.featureCards}
            className="bg-indigo-50 -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 " icon={faUsers} />
              <p className="font-header text-2xl font-extrabold tracking-tighter">
                Share your intercom with anyone you live with!
              </p>
            </div>
            <div>
              <p className="py-5">When someone dials your entry
              intercom, <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> will
              call everyone who lives with you — like your partner or roommates. Anyone can answer the entry intercom and let people in.
            <br /><br />And of course, you always have full control over who gets called.
            </p>
            </div>
            <div>
              <NavLink to="/signup">
                <LoaderButton>Sign Up 🎉</LoaderButton>
              </NavLink>
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-indigo-50 -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 " icon={faTruck} />
              <p className="font-header text-2xl font-extrabold tracking-tighter">Expecting a delivery?</p>
            </div>
            <div>
              <p className="py-5">Can’t wait to get your latest online delivery? Waiting for your take-out order to arrive?
              <br /><br />With <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>,
              anyone can answer the entry intercom call.
            </p>
            </div>
            <div>
              <NavLink to="/signup">
                <LoaderButton>Sign Up 🎉</LoaderButton>
              </NavLink></div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-indigo-50 -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 " icon={faConciergeBell} />
              <p className="font-header text-2xl font-extrabold tracking-tighter">Do you have short term tenants?</p>
            </div>
            <div>
              <p className="py-5">With <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>,
              you can forward the entry intercom to your tenants phone number during their stay and remove it when they leave.
              <br /><br />
              Let your tenants conveniently accept food deliveries or online shopping packages.
              </p>
            </div>
            <div>
              <NavLink to="/signup">
                <LoaderButton>Sign Up 🎉</LoaderButton>
              </NavLink>
            </div>
          </motion.div>
        </div>
      </motion.div>
      <motion.div variants={animationVariants.featureCardsContainer}
        className="col-span-3 text-left bg-indigo-50 pt-10 pb-10 px-5 lg:px-24">
        <motion.p variants={animationVariants.featureCards}
          className="font-header text-4xl font-extrabold tracking-tighter pb-10 px-5">
          Features
        </motion.p>
        <div className="grid gap-4 grid-rows-none lg:grid-cols-2">
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 mr-1" icon={faUser} />
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 mr-1" icon={faUser} />
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 mr-1" icon={faUser} />
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 mr-1" icon={faUser} />
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 mr-1" icon={faUser} />

              <p className="font-header text-2xl font-extrabold tracking-tighter">Works with up to 5 people!</p>
            </div>
            <div>
              <p className="py-5">
                <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> can
              call up to 5 phone numbers of your choice on every entry intercom call.
              </p>
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 " icon={faInfinity} />
              <p className="font-header text-2xl font-extrabold tracking-tighter">Unlimited usage!</p>
            </div>
            <div>
              <p className="py-5">
                Use <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> as
                little or as often as you need.
                <br />There's no limit!
              </p>
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 " icon={faGamepad} />
              <p className="font-header text-2xl font-extrabold tracking-tighter">Instant Real Time Control!</p>
            </div>
            <div>
              <p className="py-5">
                Update the list of people <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> will
                call as many times as you need. Changes take effect immediately!
              </p>
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <p className="text-3xl font-extrabold mb-2 text-indigo-600">(###)###-####</p>
              <p className="font-header text-2xl font-extrabold tracking-tighter">Get a local phone number to use.</p>
            </div>
            <div>
              <p className="py-5">
                <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> will
                provide you with a local number to use with your entry intercom system.
              </p>
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:col-span-2 lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 " icon={faGlobeAmericas} />
              <p className="font-header text-2xl font-extrabold tracking-tighter">Supported Locations.</p>
            </div>
            <div>
              <p className="py-5">
                <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> currently supports the following locations:
              </p>
              <div>
                <p className="font-extrabold text-lg w-full border-b border-indigo-600">
                  <motion.button
                    style={buttonStyle}
                    variants={animationVariants}
                    animate={displayCanadaCities ? ['buttonClicked'] : ['buttonNotClicked']}
                    className="pr-2 focus:outline-none"
                    onClick={() => setDisplayCanadaCities(!displayCanadaCities)}>
                    {displayCanadaCities ?
                      <span><FontAwesomeIcon className="text-indigo-600 " icon={faMinusSquare} /></span>
                      : <span><FontAwesomeIcon className="text-indigo-600 " icon={faPlusSquare} /></span>}
                  </motion.button>
                  <button className="font-extrabold text-lg focus:outline-none" onClick={() => setDisplayCanadaCities(!displayCanadaCities)}>Canada 🇨🇦</button>
                </p>
              </div>
              {!displayCanadaCities &&
                <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  className="pt-1 text-lg">
                  23 cities supported in Canada!
                </motion.div>
              }
              {displayCanadaCities &&
                <motion.div layout
                  initial={{ y: -25, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  className="flex flex-col lg:flex-row">
                  <div className="flex-1">
                    <ul>
                      <li className="font-extrabold pt-2 text-sm">Alberta</li>
                      <li>Calgary</li>
                      <li>Edmonton</li>
                      <li className="font-extrabold pt-2 text-sm">British Columbia</li>
                      <li>Vancouver</li>
                      <li>Victoria</li>
                      <li className="font-extrabold pt-2 text-sm">Manitoba</li>
                      <li>Winnipeg</li>
                      <li className="font-extrabold pt-2 text-sm">New Brunswick</li>
                      <li>Fredericton</li>
                      <li className="font-extrabold pt-2 text-sm">Nova Scotia</li>
                      <li>Halifax</li>
                    </ul>
                  </div>
                  <div className="flex-1">
                    <ul>
                      <li className="font-extrabold pt-2 text-sm">Ontario</li>
                      <li>Barrie</li>
                      <li>Hamilton</li>
                      <li>Kingston</li>
                      <li>Kitchener–Waterloo</li>
                      <li>London</li>
                      <li>Oshawa</li>
                      <li>Ottawa</li>
                      <li>St. Catharines–Niagara Falls</li>
                      <li>Toronto</li>
                      <li>Windsor</li>
                    </ul>
                  </div>
                  <div className="flex-1">
                    <ul>
                      <li className="font-extrabold pt-2 text-sm">Prince Edward Island</li>
                      <li>Charlottetown</li>
                      <li className="font-extrabold pt-2 text-sm">Quebec</li>
                      <li>Gatineau</li>
                      <li>Montreal</li>
                      <li>Quebec City</li>
                      <li className="font-extrabold pt-2 text-sm">Saskatchewan</li>
                      <li>Regina</li>
                      <li>Saskatoon</li>
                    </ul>
                  </div>
                </motion.div>}
              <div>
                <p className="font-extrabold text-lg w-full border-b border-indigo-600 pt-5">
                  <motion.button
                    style={buttonStyle}
                    variants={animationVariants}
                    animate={displayUsaCities ? 'buttonClicked' : ['buttonNotClicked']}
                    className="pr-2 focus:outline-none"
                    onClick={() => setDisplayUsaCities(!displayUsaCities)}>
                    {displayUsaCities ?
                      <span><FontAwesomeIcon className="text-indigo-600 " icon={faMinusSquare} /></span>
                      : <span><FontAwesomeIcon className="text-indigo-600 " icon={faPlusSquare} /></span>}
                  </motion.button>
                  <button className="font-extrabold text-lg focus:outline-none" onClick={() => setDisplayUsaCities(!displayUsaCities)}>United States 🇺🇸</button>
                </p>
              </div>
              {!displayUsaCities &&
                <motion.div
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  className="pt-1 text-lg">
                  59 cities supported in the United States!
                </motion.div>
              }
              {displayUsaCities &&
                <motion.div layout
                  initial={{ y: -25, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  className="flex flex-col lg:flex-row">
                  <div className="flex-1">
                    <ul>
                      <li className="font-extrabold pt-2 text-sm">Arizona</li>
                      <li>Mesa</li>
                      <li>Phoenix</li>
                      <li>Tucson</li>
                      <li className="font-extrabold pt-2 text-sm">California</li>
                      <li>Fresno</li>
                      <li>Long Beach</li>
                      <li>Los Angeles</li>
                      <li>Oakland</li>
                      <li>Sacremento</li>
                      <li>San Diego</li>
                      <li>San Francisco</li>
                      <li>San Jose</li>
                      <li className="font-extrabold pt-2 text-sm">Colorado</li>
                      <li>Colorado Springs</li>
                      <li>Denver</li>
                      <li className="font-extrabold pt-2 text-sm">Connecticut</li>
                      <li>Bridgeport</li>
                      <li className="font-extrabold pt-2 text-sm">District of Columbia</li>
                      <li>Washington DC</li>
                      <li className="font-extrabold pt-2 text-sm">Florida</li>
                      <li>Jacksonville</li>
                      <li>Miami</li>
                      <li>Tampa</li>
                      <li className="font-extrabold pt-2 text-sm">Georgia</li>
                      <li>Atlanta</li>
                      <li className="font-extrabold pt-2 text-sm">Idaho</li>
                      <li>Boise</li>
                      <li className="font-extrabold pt-2 text-sm">Illinois</li>
                      <li>Chicago</li>
                      <li className="font-extrabold pt-2 text-sm">Indiana</li>
                      <li>Indianapolis</li>
                    </ul>
                  </div>
                  <div className="flex-1">
                    <ul>
                      <li className="font-extrabold pt-2 text-sm">Kansas</li>
                      <li>Wichita</li>
                      <li className="font-extrabold pt-2 text-sm">Kentucky</li>
                      <li>Louisville</li>
                      <li className="font-extrabold pt-2 text-sm">Louisiana</li>
                      <li>Baton Rouge</li>
                      <li>New Orleans</li>
                      <li className="font-extrabold pt-2 text-sm">Maryland</li>
                      <li>Baltimore</li>
                      <li className="font-extrabold pt-2 text-sm">Massachusetts</li>
                      <li>Boston</li>
                      <li className="font-extrabold pt-2 text-sm">Michigan</li>
                      <li>Detroit</li>
                      <li className="font-extrabold pt-2 text-sm">Minnesota</li>
                      <li>Minneapolis</li>
                      <li className="font-extrabold pt-2 text-sm">Missouri</li>
                      <li>Kansas City</li>
                      <li className="font-extrabold pt-2 text-sm">Nebraska</li>
                      <li>Lincoln</li>
                      <li>Omaha</li>
                      <li className="font-extrabold pt-2 text-sm">Nevada</li>
                      <li>Las Vegas</li>
                      <li className="font-extrabold pt-2 text-sm">New Jersey</li>
                      <li>Newark</li>
                      <li className="font-extrabold pt-2 text-sm">New Mexico</li>
                      <li>Albuquerque</li>
                      <li className="font-extrabold pt-2 text-sm">New York</li>
                      <li>New York City</li>
                      <li className="font-extrabold pt-2 text-sm">North Carolina</li>
                      <li>Charlotte</li>
                      <li>Raleigh</li>
                    </ul>
                  </div>
                  <div className="flex-1">
                    <ul>
                      <li className="font-extrabold pt-2 text-sm">Ohio</li>
                      <li>Columbus</li>
                      <li className="font-extrabold pt-2 text-sm">Oklahoma</li>
                      <li>Oklahoma City</li>
                      <li>Tulsa</li>
                      <li className="font-extrabold pt-2 text-sm">Oregon</li>
                      <li>Portland</li>
                      <li className="font-extrabold pt-2 text-sm">Pennsylvania</li>
                      <li>Philadelphia</li>
                      <li className="font-extrabold pt-2 text-sm">Rhode Island</li>
                      <li>Providence</li>
                      <li className="font-extrabold pt-2 text-sm">Tennessee</li>
                      <li>Memphis</li>
                      <li>Nashville</li>
                      <li className="font-extrabold pt-2 text-sm">Texas</li>
                      <li>Arlington</li>
                      <li>Austin</li>
                      <li>Dallas</li>
                      <li>El Paso</li>
                      <li>Fort Worth</li>
                      <li>Houston</li>
                      <li>San Antonio</li>
                      <li className="font-extrabold pt-2 text-sm">Virginia</li>
                      <li>Richmond</li>
                      <li>Virginia Beach</li>
                      <li className="font-extrabold pt-2 text-sm">Washington</li>
                      <li>Seattle</li>
                      <li className="font-extrabold pt-2 text-sm">Wisconsin</li>
                      <li>Madison</li>
                      <li>Milwaukee</li>
                    </ul>
                  </div>
                </motion.div>
              }
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <FontAwesomeIcon className="text-4xl mb-2 text-indigo-600 " icon={faEllipsisH} />
              <p className="font-header text-2xl font-extrabold tracking-tighter">(Coming Soon)</p>
            </div>
            <div>
              <ul className="list-none py-5">
                <li>
                  <FontAwesomeIcon className="mb-1 text-indigo-600 align-middle" icon={faGlassCheers} /> Party Mode
                  </li>
                <li>
                  <FontAwesomeIcon className="mb-1 text-indigo-600 align-middle" icon={faVolumeMute} /> Do Not Disturb Mode
                  </li>
              </ul>
            </div>
          </motion.div>
        </div>
        <motion.div variants={animationVariants.featureCards} className="text-center grid-cols-3 pt-10">
          <NavLink to="/signup">
            <LoaderButton size="hero">Sign Up 🎉</LoaderButton>
          </NavLink>
          <div className="flex content-center text-center mt-1 text-base lg:text-lg tracking-tighter font-bold">
            <div className="font-header m-auto bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 via-pink-500 to-red-600">
              1 month free trial!
              </div>
          </div>
        </motion.div>
      </motion.div>
      <motion.div variants={animationVariants.featureCardsContainer}
        className="col-span-3 text-center bg-white pt-10 pb-10 px-5 lg:px-24">
        <motion.p variants={animationVariants.featureCards}
          className="font-header text-4xl font-extrabold tracking-tighter pb-10">
          Pricing
        </motion.p>
        <div className="grid gap-4 grid-rows-none lg:grid-cols-1">
          <motion.div variants={animationVariants.featureCards}
            className="bg-indigo-50 -mx-5 lg:w-1/2 lg:mx-auto p-10 lg:rounded-lg leading-relaxed">
            <p className="font-header font-extrabold text-2xl tracking-tighter">Standard Plan</p>
            <p className="font-header text-3xl font-bold tracking-tighter">$<span className="border-dotted border-b-2 border-indigo-600" ref={setTriggerRef}>4.99</span> <span className="font-normal text-xl">per month!</span></p>
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip-container text-xs' })}
              >
                <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                You will be charged <br/>in the currency of your <br/>phone number location.
              </div>
            )}
            <p className="my-5 text-sm">
              Try <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> free for 1 month!
              <br />Cancel anytime, no questions asked.
            </p>
            <ul className="list-none list-inside pb-5">
              <li>Works with up to 5 people!</li>
              <li>Unlimited usage!</li>
              <li>Instant real time control!</li>
              <li>Works with voicemail. <NavLink to="/help#works-with-voicemail"><FontAwesomeIcon className="text-sm mb-1 text-indigo-600 align-middle" icon={faInfoCircle} /></NavLink></li>
              <li>100% money back guarantee! Cancel anytime.</li>
            </ul>
            <NavLink to="/signup">
              <LoaderButton>Sign Up 🎉</LoaderButton>
            </NavLink>
          </motion.div>
        </div>
      </motion.div>
      <motion.div variants={animationVariants.featureCardsContainer}
        className="col-span-3 text-center bg-indigo-50 pt-10 pb-10 px-5 lg:px-24">
        <motion.p variants={animationVariants.featureCards}
          className="font-header text-4xl font-extrabold tracking-tighter pb-10">
          How <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> Works
        </motion.p>
        <div className="grid gap-4 grid-rows-none lg:grid-cols-3">
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <p className="font-header text-2xl font-extrabold tracking-tighter">Step 1</p>
              <FontAwesomeIcon className="text-4xl mt-2 text-indigo-600 " icon={faUserPlus} />
            </div>
            <div>
              <p className="py-5">
                Sign up and <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> will
                assign you a phone number.
              </p>
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <p className="font-header text-2xl font-extrabold tracking-tighter">Step 2</p>
              <FontAwesomeIcon className="text-4xl mt-2 text-indigo-600 " icon={faBuilding} />
            </div>
            <div>
              <p className="py-5">
                Ask your building management to update your entry intercom system with your
              new <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> number.
              </p>
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <p className="font-header text-2xl font-extrabold tracking-tighter">Step 3</p>
              <FontAwesomeIcon className="text-4xl mt-2 text-indigo-600 " icon={faAddressBook} />
            </div>
            <div>
              <p className="py-5">
                Setup your call list on
              the <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> web site.
              </p>
            </div>
          </motion.div>
        </div>
        <div className="grid gap-4 grid-rows-none lg:grid-cols-2 pt-4">
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <p className="font-header text-2xl font-extrabold tracking-tighter">Step 4</p>
              <FontAwesomeIcon className="text-4xl mt-2 text-indigo-600 " icon={faPhoneVolume} />
            </div>
            <div>
              <p className="py-5">
                When someone dials your intercom, <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> will
                call everyone on your call list!
              </p>
            </div>
          </motion.div>
          <motion.div variants={animationVariants.featureCards}
            className="bg-white -mx-5 flex flex-col justify-between lg:mx-0 p-10 lg:rounded-lg">
            <div>
              <p className="font-header text-2xl font-extrabold tracking-tighter">Step 5</p>
              <p className="text-4xl">🎉</p>
            </div>
            <div>
              <p className="py-5">
                Ta da!
              <br />With <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> — now
              your intercom works with everyone you live with!
              </p>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
}