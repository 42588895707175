import "./LoaderButton.css";

export default function LoaderButton({
  isLoading,
  className = "",
  disabled = false,
  size = "",
  ...props
}) {

  if (size === 'sm') {
    return (
      <button
        type="button"
        disabled={disabled || isLoading}
        className={`w-full 
        inline-flex 
        justify-center 
        rounded-md 
        border 
        border-transparent 
        shadow-sm 
        px-2 py-1  
        text-sm
        font-semibold 
        text-white 
        items-center
        ${(disabled || isLoading) ? 'bg-indigo-100' : 'bg-indigo-600 hover:bg-indigo-500'}
        focus:outline-none 
        focus:ring-2 
        focus:ring-offset-2
        focus:ring-red-400 
        sm:w-auto 
        sm:text-sm
        ${(disabled || isLoading) ? 'cursor-not-allowed' : ''}`}
        {...props}
      >
        {isLoading &&
          <svg width="20" height="20" fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
            </path>
          </svg>
        }
        {props.children}
      </button>
    );
  } else if (size === 'hero') {
    return (
      <button
        type="button"
        disabled={disabled || isLoading}
        className={`w-full 
        inline-flex 
        justify-center 
        rounded-md 
        border 
        border-transparent 
        shadow-sm 
        px-6 py-3  
        text-xl
        font-bold 
        text-white 
        items-center
        ${(disabled || isLoading) ? 'bg-indigo-100' : 'bg-indigo-600 hover:bg-indigo-500'}
        focus:outline-none 
        focus:ring-2 
        focus:ring-offset-2
        focus:ring-red-400 
        sm:w-auto 
        sm:text-xl
        ${(disabled || isLoading) ? 'cursor-not-allowed' : ''}`}
        {...props}
      >
        {isLoading &&
          <svg width="20" height="20" fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
            </path>
          </svg>
        }
        {props.children}
      </button>
    );
  } else {
    return (
      <button
        type="button"
        disabled={disabled || isLoading}
        className={`w-full 
        inline-flex 
        justify-center 
        rounded-md 
        border 
        border-transparent 
        shadow-sm 
        px-4 py-2  
        text-base 
        font-semibold 
        text-white 
        items-center
        ${(disabled || isLoading) ? 'bg-indigo-100' : 'bg-indigo-600 hover:bg-indigo-500'}
        focus:outline-none 
        focus:ring-2 
        focus:ring-offset-2
        focus:ring-red-400 
        sm:w-auto 
        sm:text-sm
        ${(disabled || isLoading) ? 'cursor-not-allowed' : ''}`}
        {...props}
      >
        {isLoading &&
          <svg width="20" height="20" fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
            <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
            </path>
          </svg>
        }
        {props.children}
      </button>
    );
  }
}