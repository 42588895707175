import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import "./Signup.css";

import { Helmet } from "react-helmet";

// React Router History Hook
import { useHistory, useLocation, NavLink } from "react-router-dom";

import LoaderButton from "../components/LoaderButton";

import { Listbox, Transition } from '@headlessui/react'

import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import { Auth } from "aws-amplify";

import { motion } from "framer-motion"

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const locations = [
    {
        name: 'Choose...', unavailable: true
    },
    {
        name: 'CANADA 🇨🇦', unavailable: true, label: true
    },
    {
        name: 'Alberta', unavailable: true, label: true
    },
    {
        name: 'Calgary', unavailable: false,
        BuzzrrLocality: 'Calgary-AB-Canada'
    },
    {
        name: 'Edmonton', unavailable: false,
        BuzzrrLocality: 'Edmonton-AB-Canada'
    },
    {
        name: 'British Columbia', unavailable: true, label: true
    },
    {
        name: 'Vancouver', unavailable: false,
        BuzzrrLocality: 'Vancouver-BC-Canada'
    },
    {
        name: 'Victoria', unavailable: false,
        BuzzrrLocality: 'Victoria-BC-Canada'
    },
    {
        name: 'Manitoba', unavailable: true, label: true
    },
    {
        name: 'Winnipeg', unavailable: false,
        BuzzrrLocality: 'Winnipeg-MB-Canada'
    },
    {
        name: 'New Brunswick', unavailable: true, label: true
    },
    {
        name: 'Fredericton', unavailable: false,
        BuzzrrLocality: 'Fredericton-NB-Canada'
    },
    {
        name: 'Nova Scotia', unavailable: true, label: true
    },
    {
        name: 'Halifax', unavailable: false,
        BuzzrrLocality: 'Halifax-NS-Canada'
    },
    {
        name: 'Ontario', unavailable: true, label: true
    },
    {
        name: 'Barrie', unavailable: false,
        BuzzrrLocality: 'Barrie-ON-Canada'
    },
    {
        name: 'Hamilton', unavailable: false,
        BuzzrrLocality: 'Hamilton-ON-Canada'
    },
    {
        name: 'Kingston', unavailable: false,
        BuzzrrLocality: 'Kingston-ON-Canada'
    },
    {
        name: 'Kitchener–Waterloo', unavailable: false,
        BuzzrrLocality: 'Kitchener-ON-Canada'
    },
    {
        name: 'London', unavailable: false,
        BuzzrrLocality: 'London-ON-Canada'
    },
    {
        name: 'Oshawa', unavailable: false,
        BuzzrrLocality: 'Oshawa-ON-Canada'
    },
    {
        name: 'Ottawa', unavailable: false,
        BuzzrrLocality: 'Ottawa-ON-Canada'
    },
    {
        name: 'St. Catharines–Niagara Falls', unavailable: false,
        BuzzrrLocality: 'NiagaraFalls-ON-Canada'
    },
    {
        name: 'Toronto', unavailable: false,
        BuzzrrLocality: 'Toronto-ON-Canada'
    },
    {
        name: 'Windsor', unavailable: false,
        BuzzrrLocality: 'Windsor-ON-Canada'
    },
    {
        name: 'Prince Edward Island', unavailable: true, label: true
    },
    {
        name: 'Charlottetown', unavailable: false,
        BuzzrrLocality: 'Charlottetown-PE-Canada'
    },
    {
        name: 'Quebec', unavailable: true, label: true
    },
    {
        name: 'Gatineau', unavailable: false,
        BuzzrrLocality: 'Gatineau-QC-Canada'
    },
    {
        name: 'Montreal', unavailable: false,
        BuzzrrLocality: 'Montreal-QC-Canada'
    },
    {
        name: 'Quebec City', unavailable: false,
        BuzzrrLocality: 'QuebecCity-QC-Canada'
    },
    {
        name: 'Saskatchewan', unavailable: true, label: true
    },
    {
        name: 'Regina', unavailable: false,
        BuzzrrLocality: 'Regina-SK-Canada'
    },
    {
        name: 'Saskatoon', unavailable: false,
        BuzzrrLocality: 'Saskatoon-SK-Canada'
    },
    { name: 'United States 🇺🇸 ', unavailable: true, label: true },
    { name: 'Arizona', unavailable: true, label: true },
    { name: 'Mesa', unavailable: false, BuzzrrLocality: 'Mesa-AZ-US' },
    {
        name: 'Phoenix',
        unavailable: false,
        BuzzrrLocality: 'Phoenix-AZ-US'
    },
    {
        name: 'Tucson',
        unavailable: false,
        BuzzrrLocality: 'Tucson-AZ-US'
    },
    { name: 'California', unavailable: true, label: true },
    {
        name: 'Fresno',
        unavailable: false,
        BuzzrrLocality: 'Fresno-CA-US'
    },
    {
        name: 'Long Beach',
        unavailable: false,
        BuzzrrLocality: 'Long-Beach-CA-US'
    },
    {
        name: 'Los Angeles',
        unavailable: false,
        BuzzrrLocality: 'Los-Angeles-CA-US'
    },
    {
        name: 'Oakland',
        unavailable: false,
        BuzzrrLocality: 'Oakland-CA-US'
    },
    {
        name: 'Sacremento',
        unavailable: false,
        BuzzrrLocality: 'Sacremento-CA-US'
    },
    {
        name: 'San Diego',
        unavailable: false,
        BuzzrrLocality: 'San-Diego-CA-US'
    },
    {
        name: 'San Francisco',
        unavailable: false,
        BuzzrrLocality: 'San-Francisco-CA-US'
    },
    {
        name: 'San Jose',
        unavailable: false,
        BuzzrrLocality: 'San-Jose-CA-US'
    },
    { name: 'Colorado', unavailable: true, label: true },
    {
        name: 'Colorado Springs',
        unavailable: false,
        BuzzrrLocality: 'Colorado-Springs-CO-US'
    },
    {
        name: 'Denver',
        unavailable: false,
        BuzzrrLocality: 'Denver-CO-US'
    },
    { name: 'Connecticut', unavailable: true, label: true },
    {
        name: 'Bridgeport',
        unavailable: false,
        BuzzrrLocality: 'Bridgeport-CT-US'
    },
    { name: 'District of Columbia', unavailable: true, label: true },
    {
        name: 'Washington DC',
        unavailable: false,
        BuzzrrLocality: 'Washington-DC-DC-US'
    },
    { name: 'Florida', unavailable: true, label: true },
    {
        name: 'Jacksonville',
        unavailable: false,
        BuzzrrLocality: 'Jacksonville-FL-US'
    },
    { name: 'Miami', unavailable: false, BuzzrrLocality: 'Miami-FL-US' },
    { name: 'Tampa', unavailable: false, BuzzrrLocality: 'Tampa-FL-US' },
    { name: 'Georgia', unavailable: true, label: true },
    {
        name: 'Atlanta',
        unavailable: false,
        BuzzrrLocality: 'Atlanta-GA-US'
    },
    { name: 'Idaho', unavailable: true, label: true },
    { name: 'Boise', unavailable: false, BuzzrrLocality: 'Boise-ID-US' },
    { name: 'Illinois', unavailable: true, label: true },
    {
        name: 'Chicago',
        unavailable: false,
        BuzzrrLocality: 'Chicago-IL-US'
    },
    { name: 'Indiana', unavailable: true, label: true },
    {
        name: 'Indianapolis',
        unavailable: false,
        BuzzrrLocality: 'Indianapolis-IN-US'
    },
    { name: 'Kansas', unavailable: true, label: true },
    {
        name: 'Wichita',
        unavailable: false,
        BuzzrrLocality: 'Wichita-KS-US'
    },
    { name: 'Kentucky', unavailable: true, label: true },
    {
        name: 'Louisville',
        unavailable: false,
        BuzzrrLocality: 'Louisville-KY-US'
    },
    { name: 'Louisiana', unavailable: true, label: true },
    {
        name: 'Baton Rouge',
        unavailable: false,
        BuzzrrLocality: 'Baton-Rouge-LA-US'
    },
    {
        name: 'New Orleans',
        unavailable: false,
        BuzzrrLocality: 'New-Orleans-LA-US'
    },
    { name: 'Maryland', unavailable: true, label: true },
    {
        name: 'Baltimore',
        unavailable: false,
        BuzzrrLocality: 'Baltimore-MD-US'
    },
    { name: 'Massachusetts', unavailable: true, label: true },
    {
        name: 'Boston',
        unavailable: false,
        BuzzrrLocality: 'Boston-MA-US'
    },
    { name: 'Michigan', unavailable: true, label: true },
    {
        name: 'Detroit',
        unavailable: false,
        BuzzrrLocality: 'Detroit-MI-US'
    },
    { name: 'Minnesota', unavailable: true, label: true },
    {
        name: 'Minneapolis',
        unavailable: false,
        BuzzrrLocality: 'Minneapolis-MN-US'
    },
    { name: 'Missouri', unavailable: true, label: true },
    {
        name: 'Kansas City',
        unavailable: false,
        BuzzrrLocality: 'Kansas-City-MO-US'
    },
    { name: 'Nebraska', unavailable: true, label: true },
    {
        name: 'Lincoln',
        unavailable: false,
        BuzzrrLocality: 'Lincoln-NE-US'
    },
    { name: 'Omaha', unavailable: false, BuzzrrLocality: 'Omaha-NE-US' },
    { name: 'Nevada', unavailable: true, label: true },
    {
        name: 'Las Vegas',
        unavailable: false,
        BuzzrrLocality: 'Las-Vegas-NV-US'
    },
    { name: 'New Jersey', unavailable: true, label: true },
    {
        name: 'Newark',
        unavailable: false,
        BuzzrrLocality: 'Newark-NJ-US'
    },
    { name: 'New Mexico', unavailable: true, label: true },
    {
        name: 'Albuquerque',
        unavailable: false,
        BuzzrrLocality: 'Albuquerque-NM-US'
    },
    { name: 'New York', unavailable: true, label: true },
    {
        name: 'New York City',
        unavailable: false,
        BuzzrrLocality: 'New-York City-NY-US'
    },
    { name: 'North Carolina', unavailable: true, label: true },
    {
        name: 'Charlotte',
        unavailable: false,
        BuzzrrLocality: 'Charlotte-NC-US'
    },
    {
        name: 'Raleigh',
        unavailable: false,
        BuzzrrLocality: 'Raleigh-NC-US'
    },
    { name: 'Ohio', unavailable: true, label: true },
    {
        name: 'Columbus',
        unavailable: false,
        BuzzrrLocality: 'Columbus-OH-US'
    },
    { name: 'Oklahoma', unavailable: true, label: true },
    {
        name: 'Oklahoma City',
        unavailable: false,
        BuzzrrLocality: 'Oklahoma-City-OK-US'
    },
    { name: 'Tulsa', unavailable: false, BuzzrrLocality: 'Tulsa-OK-US' },
    { name: 'Oregon', unavailable: true, label: true },
    {
        name: 'Portland',
        unavailable: false,
        BuzzrrLocality: 'Portland-OR-US'
    },
    { name: 'Pennsylvania', unavailable: true, label: true },
    {
        name: 'Philadelphia',
        unavailable: false,
        BuzzrrLocality: 'Philadelphia-PA-US'
    },
    { name: 'Rhode Island', unavailable: true, label: true },
    {
        name: 'Providence',
        unavailable: false,
        BuzzrrLocality: 'Providence-RI-US'
    },
    { name: 'Tennessee', unavailable: true, label: true },
    {
        name: 'Memphis',
        unavailable: false,
        BuzzrrLocality: 'Memphis-TN-US'
    },
    {
        name: 'Nashville',
        unavailable: false,
        BuzzrrLocality: 'Nashville-TN-US'
    },
    { name: 'Texas', unavailable: true, label: true },
    {
        name: 'Arlington',
        unavailable: false,
        BuzzrrLocality: 'Arlington-TX-US'
    },
    {
        name: 'Austin',
        unavailable: false,
        BuzzrrLocality: 'Austin-TX-US'
    },
    {
        name: 'Dallas',
        unavailable: false,
        BuzzrrLocality: 'Dallas-TX-US'
    },
    {
        name: 'El Paso',
        unavailable: false,
        BuzzrrLocality: 'El-Paso-TX-US'
    },
    {
        name: 'Fort Worth',
        unavailable: false,
        BuzzrrLocality: 'Fort-Worth-TX-US'
    },
    {
        name: 'Houston',
        unavailable: false,
        BuzzrrLocality: 'Houston-TX-US'
    },
    {
        name: 'San Antonio',
        unavailable: false,
        BuzzrrLocality: 'San-Antonio-TX-US'
    },
    { name: 'Virginia', unavailable: true, label: true },
    {
        name: 'Richmond',
        unavailable: false,
        BuzzrrLocality: 'Richmond-VA-US'
    },
    {
        name: 'Virginia Beach',
        unavailable: false,
        BuzzrrLocality: 'Virginia-Beach-VA-US'
    },
    { name: 'Washington', unavailable: true, label: true },
    {
        name: 'Seattle',
        unavailable: false,
        BuzzrrLocality: 'Seattle-WA-US'
    },
    { name: 'Wisconsin', unavailable: true, label: true },
    {
        name: 'Madison',
        unavailable: false,
        BuzzrrLocality: 'Madison-WI-US'
    },
    {
        name: 'Milwaukee',
        unavailable: false,
        BuzzrrLocality: 'Milwaukee-WI-US'
    }
]

const passwordHintAnimation = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: "-15%" },
}

const DEBUG = false;

function LocationListBox(props) {
    const [selectedLocation, setSelectedLocation] = useState(locations[0]);

    // Get a ref to the Listbox Button.
    const buttonRef = useRef(null);

    useEffect(() => {
        // Pass selected value back up. 
        // This effect causes the update to only happen after the component is rendered.
        props.selectedItem(selectedLocation);
    }, [props, selectedLocation]);

    useLayoutEffect(() => {
        // Set TabIndex on listbox button
        buttonRef.current.tabIndex = 0;
    }, [])

    useEffect(() => {
        // Focus to the country dropdown box on load.
        buttonRef.current.focus();
    }, [])


    return (
        <Listbox
            as="div"
            className="space-y-1"
            value={selectedLocation}
            onChange={setSelectedLocation}
        >
            {({ open }) => (
                <>
                    <Listbox.Label className="block text-xs leading-5 font-base">
                        Phone Number Location
                    </Listbox.Label>
                    <div className="relative">
                        <span className="inline-block w-full rounded-md shadow-sm">
                            <Listbox.Button ref={buttonRef}
                                className="cursor-default relative w-full rounded shadow 
                                    border border-gray-300 bg-white pl-3 pr-10 py-2 text-left 
                                    focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 
                                    transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                <span className="block truncate">{selectedLocation.name}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg
                                        className="h-5 w-5 text-gray-400"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                    >
                                        <path
                                            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </Listbox.Button>
                        </span>

                        <Transition
                            show={open}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            className="z-20 absolute mt-1 w-full border rounded-md bg-white border-indigo-500 shadow-lg"
                        >
                            <Listbox.Options
                                static
                                className="max-h-80 rounded-md py-1 text-base leading-6 shadow-lg overflow-auto
                                        focus:outline-none sm:text-sm sm:leading-5">
                                {locations.map((location) => (
                                    <Listbox.Option className="focus:outline-none" key={location.key} value={location} disabled={location.unavailable}>
                                        {({ selected, active }) => (
                                            <div
                                                className={`${active
                                                    ? "text-white bg-indigo-600"
                                                    : "text-gray-900"
                                                    } cursor-default select-none relative py-2 pl-8 pr-4 focus:outline-none`}
                                            >
                                                <span
                                                    className={`${selected ? "font-semibold" : "font-normal"
                                                        } block truncate
                                                        ${location.label ? "font-semibold text-xs" : ""}
                                                        ${location.unavailable && !location.label ? "text-gray-300" : ""}`}
                                                >
                                                    {location.name}
                                                </span>
                                                {selected && (
                                                    <span
                                                        className={`${active ? "text-white" : "text-indigo-600"
                                                            } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                                    >
                                                        <svg
                                                            className="h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}


export default function Signup(props) {

    let history = useHistory();         // react-router hook for pushing URL's

    let location = useLocation();       // react-router hook used to access location object and state variables passed in.
    const confirmEmail = location.state?.confirmEmail;    // If redirected from login page, then get the passed email state value.
    const confirmPass = location.state?.confirmPass;    // If redirected from login page, then get the passed password state value.

    // React States for the form fields.
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidPasswordConfirm, setInvalidPasswordConfirm] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState('');

    // State for if handle new user or user that needs to confirm email.
    const [newUser, setNewUser] = useState(null);

    // State for any async loading.
    const [isLoading, setIsLoading] = useState(false);

    // State for handling the sign in error messages.
    const [uiMessage, setUIMessage] = useState(null);

    // State for selected Location for Phone Number
    const [selectedLocation, setSelectedLocation] = useState(false);

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip();


    // React Effect to check if this page is being called with a confirmEmail URL parameter and confirmPass state.
    // If this page is called with a confirmEmail URL parameter then show the confirm code form.\
    useEffect(function handleLoginUserNotConfirmed() {       // Handle cases where the login page redirects to signup to handle user confirmation.
        try {
            // If there is a Email and Password parameter passed in from a redirect
            if (confirmPass && confirmEmail) {      // Using optional chaining to check for confirmPass nested object.
                setNewUser(confirmEmail);           // Set the page state with a new user to force confirm code form to show.
                setEmail(confirmEmail);             // Set the email state so that when user confirms code the email is passed to AWS properly.
                setPassword(confirmPass);           // Set the email password state so that when user confirms code the page auto signs in properly.
            }
        } catch (e) {
            if (DEBUG) console.log(e);
        }
    }, [confirmPass, confirmEmail]);                     // Effect only reruns when confirmEmail state variable changes. 

    useEffect(() => {
        // Manage Password Validation UI Elements

        const PASSWORD_REGEX_PATTERN = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/);
        const PASSWORD_REGEX_PATTERN_LENGTH = new RegExp(/^\S{8,99}$/);
        const PASSWORD_REGEX_PATTERN_UPPER = new RegExp(/(?=.*[A-Z])/);
        const PASSWORD_REGEX_PATTERN_LOWER = new RegExp(/(?=.*[a-z])/);
        const PASSWORD_REGEX_PATTERN_NUMBER = new RegExp(/(?=.*[0-9])/);
        const PASSWORD_REGEX_PATTERN_SPECIAL = new RegExp(/[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]+/);

        // Test New Password to REGEX
        if (!PASSWORD_REGEX_PATTERN.test(password) && password.length > 0) {
            setInvalidPassword(true);
        } else {
            setInvalidPassword(false);
        }

        // Check that the confirmation password equals the entered password.
        if (password !== confirmPassword && confirmPassword.length > 0) {
            setInvalidPasswordConfirm(true);
        } else {
            setInvalidPasswordConfirm(false);
        }

        // Set password validation messages.
        if (password.length > 0) {
            let isPasswordValidLength, isPasswordValidUpperCharacter, isPasswordValidLowerCharacter, isPasswordValidNumber, isPasswordValidSpecialCharacter;

            PASSWORD_REGEX_PATTERN_LENGTH.test(password) ? isPasswordValidLength = '✅' : isPasswordValidLength = '❌'
            PASSWORD_REGEX_PATTERN_UPPER.test(password) ? isPasswordValidUpperCharacter = '✅' : isPasswordValidUpperCharacter = '❌'
            PASSWORD_REGEX_PATTERN_LOWER.test(password) ? isPasswordValidLowerCharacter = '✅' : isPasswordValidLowerCharacter = '❌'
            PASSWORD_REGEX_PATTERN_NUMBER.test(password) ? isPasswordValidNumber = '✅' : isPasswordValidNumber = '❌'
            PASSWORD_REGEX_PATTERN_SPECIAL.test(password) ? isPasswordValidSpecialCharacter = '✅' : isPasswordValidSpecialCharacter = '❌'

            setNewPasswordError(`Your new password must contain: \n\n ${isPasswordValidLength} at least 8 characters, 
            \n ${isPasswordValidUpperCharacter} at least 1 uppercase character, 
            \n ${isPasswordValidLowerCharacter} at least have 1 lowercase character, 
            \n ${isPasswordValidNumber} at least have 1 number,
            \n ${isPasswordValidSpecialCharacter} and at least 1 special character.`);
        } else {
            setNewPasswordError('');
        }

    }, [password, confirmPassword]);

    function validateSignUpForm() {
        // Function to validate the sign up form.

        return (
            firstName.length > 0 &&
            lastName.length > 0 &&
            email.length > 0 &&
            password.length > 0 &&
            password === confirmPassword &&
            selectedLocation.id !== 0 &&
            !invalidPassword
        );
    }

    function validateSignUpConfirmationForm() {
        // Function to validate the confirmation form.

        return confirmationCode.length > 0;
    }

    async function handleSignUpSubmit(event) {
        // Function to handle submitting the signup form.

        event.preventDefault();
        setIsLoading(true);
        setUIMessage(null);

        await Auth.signUp({         // Send AWS Cognito the sign up user name and passwords. 
            username: email,
            password: password,      // Assume that the HTML form is doing password and confirm password validation. 
            attributes: {
                given_name: firstName,
                family_name: lastName,
            },
            clientMetadata: {
                signupRequestedLocality: selectedLocation.BuzzrrLocality
            }
        }).then(user => {
            if (DEBUG) console.log(user);      // DEBUG
            setIsLoading(false);
            setNewUser(user);
            window.history.replaceState(history.state, '');   // Hack to force browser to save passwords. 
        }).catch(error => {
            if (DEBUG) console.log(error);     // DEBUG
            setIsLoading(false);
            setUIMessage(error.message);
        });
    }

    async function handleConfirmationSubmit(event) {
        // Function to handle submitting the confirmation form.

        event.preventDefault();
        setIsLoading(true);
        setUIMessage(null);

        await Auth.confirmSignUp(email, confirmationCode)                   // Confirm AWS Cognito Sign Up Confirmation Code
            .then(async () => {
                await Auth.signIn(email, password)                          // If successful confirmation, then auto log user in. 
                    .then(user => {
                        props.setAuthenticatedUserObject(user);             // Set app user object.
                        if (window.gtag) window.gtag("event", "sign_up", {});   // Send Google Analytics 'sign_up' event.
                        history.push("/dashboard");
                    });
            }).catch((error) => {                                           // Catch both Auth.confirmSignUp & Auth.signIn errors. 
                if (DEBUG) console.log(error);
                setIsLoading(false);
                setUIMessage(error.message);                                // Set the UI message.
            });
    }

    async function handleResendCode(event) {
        // Function to resend the verification code

        event.preventDefault();

        Auth.resendSignUp(email).then(() => {
            //if (DEBUG) console.log('code resent successfully');        // DEBUG
            setUIMessage('A new confirmation code was sent to ' + email);       // Set UI message to show that code was sent.
            setNewUser(true);
        }).catch(e => {
            setUIMessage(e);                                  // Set the UI message.
            if (DEBUG) console.log(e);                                   // DEBUG
        });
    }

    function showUIMessage() {
        // Function to Show a Message

        if (uiMessage === null) {                       // If there is no UI message set then don't show anything. 
            return;
        } else {
            return (                                    // If a UI message is set then show the alert. 
                <div className="bg-yellow-200 border-yellow-600 text-yellow-600 mt-5 p-2 rounded" role="alert">
                    <p className="font-sans font-medium tracking-tight">
                        <FontAwesomeIcon icon={faExclamationTriangle} /><br />
                        {uiMessage}
                    </p>
                </div>
            )
        }
    }

    function renderSignUpForm() {
        // Function to render the Confirmation Form component.

        return (
            <div className="flex flex-col p-1 md:p-10 text-center items-center">
                <Helmet>
                    <title>buzzrr - Sign Up</title>
                </Helmet>
                <form className="bg-white rounded-lg shadow p-5 lg:p-10 max-w-lg w-full"
                    onSubmit={handleSignUpSubmit}
                    autoComplete="on">
                    <span className="font-logo font-black text-3xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>
                    <p className="font-sans text-2xl font-medium tracking-tight">Sign up</p>
                    <div className="my-10 rounded-lg leading-relaxed">
                        {/* <p className="font-header font-extrabold text-2xl tracking-tighter">Standard Plan</p> */}
                        <p className="font-header text-3xl font-bold tracking-tighter">$<span className="border-dotted border-b-2 border-indigo-600" ref={setTriggerRef}>4.99</span> <span className="font-normal text-xl">per month!</span></p>
                        {visible && (
                            <div
                                ref={setTooltipRef}
                                {...getTooltipProps({ className: 'tooltip-container text-xs' })}
                            >
                                <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                                You will be charged <br/>in the currency of your <br/>phone number location.
                            </div>
                        )}
                        <p className="my-5 text-sm">
                            Try <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span> free for 1 month!
                        <br />Cancel anytime, no questions asked.
                        </p>
                        <ul className="list-none list-inside">
                            <li>Works with up to 5 people!</li>
                            <li>Unlimited usage!</li>
                            <li>Instant real time control!</li>
                            <li>Works with voicemail. <NavLink to="/help#works-with-voicemail"><FontAwesomeIcon className="text-sm mb-1 text-indigo-600 align-middle" icon={faInfoCircle} /></NavLink></li>
                            <li>100% money back guarantee! Cancel anytime.</li>
                        </ul>
                    </div>
                    <div>
                        <div className="text-left">
                            <LocationListBox selectedItem={setSelectedLocation} />
                        </div>
                        <div className="text-left mt-3 lg:mt-4">
                            <label htmlFor="first-name" className="font-sans text-xs">First Name</label>
                            <input autoFocus type="text"
                                // placeholder="What's your first name?" 
                                required
                                value={firstName} onChange={e => setFirstName(e.target.value)}
                                tabIndex="0"
                                autoComplete="on"
                                className="appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm" />
                        </div>
                        <div className="text-left">
                            <label htmlFor="last-name" className="font-sans text-xs">Last Name</label>
                            <input type="text"
                                // placeholder="What's your last name?"
                                value={lastName} onChange={e => setLastName(e.target.value)} required
                                tabIndex="0"
                                autoComplete="on"
                                className="appearance-none rounded shadow relative block w-full px-3 py-2 mb-3 lg:mb-4 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm" />
                        </div>
                        <div className="text-left">
                            <label htmlFor="email" className="font-sans text-xs">Email</label>
                            <input type="email"
                                // placeholder="What's your email?"
                                value={email} onChange={e => setEmail(e.target.value)} required
                                tabIndex="0"
                                autoComplete="on"
                                className="appearance-none rounded shadow relative block w-full px-3 py-2 mb-3 lg:mb-4 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm" />
                        </div>
                        <div className="text-left">
                            <label htmlFor="password" className="font-sans text-xs">Choose a password</label>
                            <input type="password"
                                // placeholder="Choose a password."
                                value={password} onChange={e => setPassword(e.target.value)} required
                                tabIndex="0"
                                autoComplete="on"
                                className={`${invalidPassword ? "ring ring-red-600 ring-offset-2" : ""}
                                            appearance-none rounded shadow relative block w-full px-3 py-2 mb-1 lg:mb-1 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                        </div>
                        <div className="text-left">
                            <label htmlFor="password" className="font-sans text-xs">Confirm your password</label>
                            <input type="password"
                                // placeholder="Confirm your password."
                                value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required
                                tabIndex="0"
                                autoComplete="on"
                                className={`${invalidPasswordConfirm ? "ring ring-red-600 ring-offset-2" : ""}
                                            appearance-none rounded shadow relative block w-full px-3 py-2 mb-3 lg:mb-4 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm`} />
                        </div>
                        <motion.div
                            animate={newPasswordError ? "open" : "closed"}
                            variants={passwordHintAnimation}
                            className="text-left font-sans text-xs whitespace-pre-line leading-none py-3">
                            {newPasswordError}
                        </motion.div>
                        <div className="text-sm px-5">
                            <input id="comments" name="comments" type="checkbox" required
                                tabIndex="0"
                                onChange={(e) => { e.target.setCustomValidity(''); }}
                                onInvalid={(e) => { e.target.setCustomValidity('You must accept the Terms and Conditions to create a BUZZRR Account.'); }}
                                className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                I have read and accept the <span className="font-logo font-black tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>
                            <NavLink target="_blank" to="/termsandconditions">
                                <button tabIndex="0" type="button"
                                    className="a-style hover:underline">
                                    Terms and Conditions and Privacy Policy.
                                </button>
                            </NavLink>
                        </div>
                    </div>
                    <div className="pt-5">
                        <LoaderButton isLoading={isLoading} disabled={!validateSignUpForm()} tabIndex="0" type="submit">Sign Up</LoaderButton>
                    </div>
                    {showUIMessage()}
                </form>
            </div>
        );
    }

    function renderConfirmationForm() {
        // Function to render the Confirmation Form component.

        return (
            <div className="flex flex-col p-10 text-center items-center">
                <form className="bg-white rounded-lg shadow p-5 lg:p-10 max-w-lg w-full" onSubmit={handleConfirmationSubmit}>
                    <span className="font-logo font-black text-3xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>
                    <p className="font-sans text-2xl font-medium tracking-tight pb-5">Account Verification</p>
                    <div>
                        <div className="">
                            <label htmlFor="first-name" className="font-sans text-sm">Please check your email for the confirmation code.</label>
                            <input autoFocus type="tel" autoComplete="off" placeholder="Enter verification code." required
                                value={confirmationCode} onChange={e => setConfirmationCode(e.target.value)}
                                className="appearance-none rounded shadow relative block w-full px-3 py-2 mb-2 lg:mb-4 border border-gray-300 
                                            placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
                                            focus:z-10 sm:text-sm" />
                        </div>
                        <p>
                            <a href="# " onClick={handleResendCode} className="text-sm a-style">
                                Resend Confirmation Code
                            </a>
                        </p>
                    </div>
                    <div className="pt-5">
                        <LoaderButton isLoading={isLoading} disabled={!validateSignUpConfirmationForm()} type="submit">Verify</LoaderButton>
                    </div>
                    {showUIMessage()}
                </form>
            </div>
        );
    }

    // Render either the new sign up form, or the confirmation form depending on newUser state.
    return (
        <div className="Signup">
            <div className="signup-white-card shadow-effect1">
                {newUser === null ? renderSignUpForm() : renderConfirmationForm()}
            </div>
        </div>
    );
}