import { useState, useEffect } from 'react';
import './App.css';

import Amplify, { Auth } from "aws-amplify";

// React Router
import { Link, NavLink, Route, Switch, Redirect, useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";

import Main from "./pages/Main";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Account from "./pages/Account";
import Setup from "./pages/Setup";
import Dashboard from "./pages/Dashboard";
import TermsAndConditions from "./pages/TermsAndConditions";
import Help from "./pages/Help";
import NotFound from "./pages/NotFound";
import { useTracking } from './components/useTracking'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSlidersH, faSignOutAlt, faSignInAlt, faUserPlus, faCopyright } from '@fortawesome/free-solid-svg-icons';

const BUZZRR_CUSTOMER_API_URL = process.env.REACT_APP_URL; //'https://api.dev.buzzrr.co';
const GET_CUSTOMER_API_ROUTE = '/api/v1/customer';
const gtagId = process.env.REACT_APP_GTAG ?? false;
const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const COGNITO_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID;

const DEBUG = false;


Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: COGNITO_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: COGNITO_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH'
  }
});

function App() {
  let history = useHistory();

  // Enable gtag page tracking. https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
  useTracking(gtagId);

  // State for if app is awaiting return of Auth authentication promises.
  // Default set to 'true' since on page reload the app will check auth state first. 
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  // State for if app is awaiting download of data from API's.
  // This state is checked before rendering the app to make sure that all requried data is downloaded before painting the app.
  const [isDownloading, setIsDownloading] = useState(true);

  // State to hold and set the current authenticated user object for the entire App.
  const [authenticatedUserObject, setAuthenticatedUserObject] = useState(null);

  // State to hold and set the current authenticated user data for the entire App.
  const [authenticatedUserData, setAuthenticatedUserData] = useState(null);

  // State variables for determining user application state for first time user signup flow.
  // These states will be based of data from ddb. 
  // If full applicaton setup is completed, set firstTimeUserSetupComplete = true.
  const [firstTimeUserSetupComplete, setFirstTimeUserSetupComplete] = useState(null);
  // If the user has an active subscription during first sign up, set userBillingSetupComplete = true.
  const [userBillingSetupComplete, setUserBillingSetupComplete] = useState(null);
  // If the user has successfull picked their number and set forwarding numbers, set userPhoneNumberSetupComplete = true.
  const [userPhoneNumberSetupComplete, setUserPhoneNumberSetupComplete] = useState(null);


  // Object containing all user state variables to be passed to the AuthenticatedRoute component. 
  const userState = {
    firstTimeUserSetupComplete, userBillingSetupComplete, userPhoneNumberSetupComplete
  };

  useEffect(() => {
    async function onLoad() {
      // Function to handle page on load tasks.

      // Check to see if there is already a user session saved in Local Storage.
      await Auth.currentAuthenticatedUser(
        { bypassCache: true }
      )
        .then(async user => {
          if (DEBUG) console.log('user', user);      // DEBUG: Show user in log. 
          setAuthenticatedUserObject(user);

          // When promise to get session auth and API data returns then tell app that it can render.
          // Causes app to finally render. 
          setIsAuthenticating(false);
        })
        .catch(err => {
          if (DEBUG) console.log('usererr', err)        // DEBUG

          // When promise to get session auth and API data returns then tell app that it can render.
          // Causes app to finally render. 
          setIsAuthenticating(false);
          setIsDownloading(false);  // Since there is no user data to download, set it false.
        });
    }

    onLoad();
  }, []);

  useEffect(() => {
    // Fetch user data from Buzzrr Customer API and set in state.
    // Effect only runs when authenticatedUserObject changes. 

    async function whenUserChanges() {
      if (authenticatedUserObject) {
        // Call API for user data.
        setIsDownloading(true);
        let fetchedUserData = await fetchUserData(authenticatedUserObject.signInUserSession.idToken.jwtToken, GET_CUSTOMER_API_ROUTE);

        // Put user data in state variable for use in the rest of the app.
        setAuthenticatedUserData(fetchedUserData);
      }
    };

    whenUserChanges();
  }, [authenticatedUserObject]);

  useEffect(() => {
    // Update user state when data is changed.
    // Effect only runs when authenticatedUserData changes. 

    async function whenUserDataChanges() {
      if (authenticatedUserData) {
        // Set user states for AuthorizedRoute component.
        // Used to help with progressing the first time user sign up flow. 
        if (authenticatedUserData.CustomerData?.BillingData?.SetupCompleted) setUserBillingSetupComplete(true);
        if (authenticatedUserData.CustomerData?.BuzzrrPhoneNumberData?.SetupCompleted) setUserPhoneNumberSetupComplete(true);
        if (authenticatedUserData.CustomerData?.BillingData?.SetupCompleted &&
          authenticatedUserData.CustomerData?.BuzzrrPhoneNumberData?.SetupCompleted) setFirstTimeUserSetupComplete(true);

        setIsDownloading(false);  // Tell app that user data download is done so that it can render.
      }
    };

    whenUserDataChanges();
  }, [authenticatedUserData]);

  async function signOut() {
    // When logging out push new URL first to prevent issues with async updates of components that have already been unloaded.
    history.push("/");      // React Router History Hook - Redirect to '/' after login.

    // Signout using AWS Amplify
    await Auth.signOut()
      .then(data => {
        setAuthenticatedUserObject(null); // Clear the current user state.
        // if (DEBUG) console.log(data)              // DEBUG: Show the result of signout in logs.
        history.go(0);                    // Tell page to reload and clear some cached data. 
        // Edge case where if a user deletes their account and signs up right away without refreshing the page causes the old user data to half load the page. 
      })
      .catch(err => {
        if (DEBUG) console.log(err)           // DEBUG: ??FUTURE SEND THESE TO CLOUDWATCH??
      });
  }

  async function fetchUserData(jwtIdToken, route, method = 'get') {
    // Get user data from the Buzzrr API
    return fetch(BUZZRR_CUSTOMER_API_URL + route, {
      method: method,
      headers: {
        'Authorization': jwtIdToken
      },
    }).then(response => {
      // Get fetch's returned promise object.
      if (DEBUG) console.log(`fetchUserData ${method}: response`);
      if (DEBUG) console.log(response)
      if (!response.ok) return response.text().then(err => {
        throw new Error(err)
      });
      return response.json();     // Parse the response into json. 
    }).then(response => {
      // Return the API data JSON object. 
      if (DEBUG) console.log('fetchUserData: good')
      if (DEBUG) console.log(response);
      setAuthenticatedUserData(response);
      return response;
    }).catch(error => {
      if (DEBUG) console.log(`fetchUserData ${method}: unhandled exception!!`, error);
      let errorObject = {
        error: true,
        message: error.message,
      }
      return errorObject;
    });
  }

  async function postUserData(jwtIdToken, route, bodyData) {
    // Get user data from the Buzzrr API
    return fetch(BUZZRR_CUSTOMER_API_URL + route, {
      method: 'post',
      headers: {
        'Authorization': jwtIdToken
      },
      body: JSON.stringify(bodyData),
    }).then(response => {
      // Get fetch's returned promise object.
      if (DEBUG) console.log('postUserData: response');
      if (DEBUG) console.log(response)
      if (!response.ok) return response.text().then(err => {
        throw new Error(err)
      });
      return response.json();     // Parse the response into json. 
    }).then(response => {
      // Return the API data JSON object. 
      if (DEBUG) console.log('postUserData: good')
      if (DEBUG) console.log(response);
      setAuthenticatedUserData(response);
      return response;
    }).catch(error => {
      if (DEBUG) console.log('postUserData: unhandled exception!!');
      let errorObject = {
        error: true,
        message: error.message,
      }
      return errorObject;
    });
  }

  async function postData(jwtIdToken, route, bodyData) {
    // Get user data from the Buzzrr API
    return fetch(BUZZRR_CUSTOMER_API_URL + route, {
      method: 'post',
      headers: {
        'Authorization': jwtIdToken
      },
      body: JSON.stringify(bodyData),
    }).then(response => {
      // Get fetch's returned promise object.
      if (DEBUG) console.log('postUserData: response');
      if (DEBUG) console.log(response)
      if (!response.ok) return response.text().then(err => {
        throw new Error(err)
      });
      return response.json();     // Parse the response into json. 
    }).then(response => {
      // Return the API data JSON object. 
      if (DEBUG) console.log('postUserData: good')
      if (DEBUG) console.log(response);
      return response;
    }).catch(error => {
      if (DEBUG) console.log('postUserData: unhandled exception!!');
      let errorObject = {
        error: true,
        message: error.message,
      }
      return errorObject;
    });
  }

  function AuthenticatedRoute({ component: C, appProps, userState, ...rest }) {
    // Protect routes that require a user to be authenticated. 
    // MANDATORY: Pass authenticatedUserObject state in appProps. 
    // If user is authenticated, then allow route.
    // If user is unauthenticated, redirect to /login route.

    // This function also checks various user states to create the first user sign up flow.
    // These states are set in the useEffect that has whenUserDataChanges in it. 
    // The 'isDownloading' flag is used to guard the App from rendering without all it's data.

    return (
      <Route
        {...rest}
        render={props => {
          if (appProps.authenticatedUserObject && !userState.userBillingSetupComplete) {
            if (props.match.path === '/account') {
              return (
                <C {...props} {...appProps} />
              )
            } else {
              return (
                <Redirect to={'/account'} />
              )
            }
          } else if (appProps.authenticatedUserObject && !userState.userPhoneNumberSetupComplete) {
            if (props.match.path === '/setup') {
              return (
                <C {...props} {...appProps} />
              )
            } else {
              return (
                <Redirect to={'/setup'} />
              )
            }
          } else if (appProps.authenticatedUserObject && userState.firstTimeUserSetupComplete) {
            return (
              <C {...props} {...appProps} />
            )
          } else {
            return (
              <Redirect
                to={`/login?redirect=${props.location.pathname}${props.location.search}`}
              />
            )
          }

        }}
      />
    );
  }

  function UnauthenticatedRoute({ component: C, appProps, ...rest }) {
    // Prevent users that are authenticated from seeing routes that are for unauthenticated users.
    // MANDATORY: Pass authenticatedUserObject state in appProps. 
    // If user is unauthenticated, then allow route.
    // If user is authenticated, redirect to either specified redirect path or dashboard page.

    // Function to parse the URL and get the query string specified in 'name'.
    function querystring(name, url = window.location.href) {
      name = name.replace(/[[]]/g, "\\$&");

      const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
      const results = regex.exec(url);

      if (!results) {
        return null;
      }
      if (!results[2]) {
        return "";
      }

      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    // Get the route in the 'redirect' query string.
    // This Unauthenticated component will redirect the user to this URL after successful login. 
    const redirect = querystring("redirect");

    return (
      <Route
        {...rest}
        render={props =>
          !appProps.authenticatedUserObject
            ? <C {...props} {...appProps} />
            : <Redirect
              to={redirect === "" || redirect === null ? "/dashboard" : redirect}
            />}
      />
    );
  }


  if (!isAuthenticating && !isDownloading) {    // Check that all auth data and user data is downloaded before rendering the app.
    return (
      <div className="App antialiased min-h-screen flex flex-col justify-between">
        <Helmet>
          <title>buzzrr - Share your apartment intercom and more!</title>
        </Helmet>
        <header className="flex sticky z-30 top-0 px-5 py-1 lg:px-24 lg:py-3 justify-between bg-indigo-50">
          <div className="flex my-auto">
            <div className="font-logo font-black tracking-tight text-3xl lg:text-4xl my-auto pr-3 sm:pr-5">
              <Link to="/"><span className="text-indigo-600">b</span>uzzrr</Link>
            </div>
            <>
              {authenticatedUserObject
                ?
                <nav className="flex font-header font-semibold text-indigo-600 my-auto">
                  <ul className="flex space-x-2 p-0 m-0">
                    <NavLink to="/dashboard">
                      <li className="text-sm text-center lg:text-base hover:bg-indigo-600 hover:text-white px-1 py-1 rounded-md">
                        <FontAwesomeIcon className="pl-1" icon={faSlidersH} /><span className="p-1 pl-2">Dashboard</span>
                      </li>
                    </NavLink>
                    <NavLink to="/account">
                      <li className="text-sm text-center lg:text-base hover:bg-indigo-600 hover:text-white px-1 pr-1 py-1 rounded-md">
                        <FontAwesomeIcon className="pl-1" icon={faUserCircle} /><span className="p-1 pl-2">Account</span>
                      </li>
                    </NavLink>
                  </ul>
                </nav>
                :
                <>
                </>
              }
            </>
          </div>
          <div className="flex-grow text-sm lg:text-base font-header text-indigo-600 my-auto">
            {authenticatedUserObject
              ? <div className="flex justify-end items-center space-x-3">
                <span className="hidden lg:block font-light my-auto">Hello {authenticatedUserObject.attributes.given_name + ' ' + authenticatedUserObject.attributes.family_name}!</span>
                <Link to="#" onClick={signOut}>
                  <div className="text-sm text-center lg:text-base font-semibold hover:bg-indigo-600 hover:text-white pl-2 pr-1 py-1 rounded-md">
                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                    </div>
                </Link>
              </div>
              : <div className="flex justify-end items-center space-x-3">
                <NavLink to="/signup">
                  <div className="text-sm text-center lg:text-base font-semibold hover:bg-indigo-600 hover:text-white pl-2 pr-1 py-1 rounded-md">
                    <FontAwesomeIcon icon={faUserPlus} /><span className="p-1">Sign Up</span>
                  </div>
                </NavLink>
                <NavLink to="/login">
                  <div className="text-sm text-center lg:text-base font-semibold hover:bg-indigo-600 hover:text-white pl-2 pr-1 py-1 rounded-md">
                    <FontAwesomeIcon icon={faSignInAlt} /><span className="p-1">Login</span>
                  </div>
                </NavLink>
              </div>
            }
          </div>
        </header>
        <section className="bg-indigo-50 flex-grow">
          <Switch>
            <Route path="/" exact>
              <Main />
            </Route>

            <UnauthenticatedRoute path="/login" exact component={Login}
              appProps={{ authenticatedUserObject, setAuthenticatedUserObject }} />
            {/* Pass state hooks as props to <Login> component to let it get and update the current logged in user. */}

            <UnauthenticatedRoute path="/signup" exact component={Signup}
              appProps={{ authenticatedUserObject, setAuthenticatedUserObject }} />
            {/* Pass state hooks as props to <Signup> component to let it get and update the current logged in user. */}

            <AuthenticatedRoute path="/account" exact component={Account}
              appProps={{ authenticatedUserObject, setAuthenticatedUserObject, authenticatedUserData, fetchUserData, postUserData, postData, firstTimeUserSetupComplete, signOut }}
              userState={userState} />
            {/* Pass state hooks as props to <Account> component to let it set and update the current logged in user. */}

            <AuthenticatedRoute path="/setup" exact component={Setup}
              appProps={{ authenticatedUserObject, setAuthenticatedUserObject, authenticatedUserData, postUserData }}
              userState={userState} />
            {/* Pass state hooks as props to <Setup> component to let it set and update the current logged in user. */}

            <AuthenticatedRoute path="/dashboard" exact component={Dashboard}
              appProps={{ authenticatedUserObject, setAuthenticatedUserObject, authenticatedUserData, postUserData }}
              userState={userState} />
            {/* Pass state hooks as props to <Dashboard> component to let it set and update the current logged in user. */}

            <Route path="/termsandconditions" exact>
              <TermsAndConditions />
            </Route>

            <Route path="/help" exact>
              <Help />
            </Route>

            {/* Catch all route. 404 error. */}
            <Route component={NotFound} />
          </Switch>
        </section>
        <footer className="font-sans text-white bg-gray-900 text-center px-5 py-10">
          <div>
            <span className="font-logo font-black text-2xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>
          </div>
          <div className="text-sm"><FontAwesomeIcon icon={faCopyright} /> 2021 BUZZRR Co.</div>
          <div className="text-sm">
            <ul className="list-none mt-2">
              <li>
                <NavLink to="/help">
                  <button className="hover:underline">Help & FAQ</button>
                </NavLink>
              </li>
              <li>
                <NavLink target="_blank" to="/termsandconditions#terms-and-conditions">
                  <button className="hover:underline">Terms and Conditions</button>
                </NavLink>
              </li>
              <li>
                <NavLink target="_blank" to="/termsandconditions#privacy-policy">
                  <button className="hover:underline">Privacy Policy</button>
                </NavLink>
              </li>
              <li>
                <NavLink target="_blank" to="/termsandconditions#return-policy">
                  <button className="hover:underline">Return Policy</button>
                </NavLink>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    );
  } else {  // If the app hasn't finished checking for auth or downloading then render loading screen.
    return (
      <div className="flex flex-col min-h-screen text-center justify-center bg-indigo-50">
        <div className="animate-pulse">
          <div className="font-sans">
            <span className="font-logo font-black text-6xl tracking-tight align-baseline"><span className="text-indigo-600">b</span>uzzrr</span>
            <p className="text-grey-900 text-xl">Share your apartment intercom!</p>
          </div>
          <div className="flex py-5 text-indigo-600 justify-center">
            <svg width="30" height="30" fill="currentColor" className="animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
              </path>
            </svg>
          </div>
        </div>
      </div>
    )
  }
}

export default App;