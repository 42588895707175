// Modal Box

import "./ConfirmActionModal.css";

import { Transition } from '@headlessui/react'
import { useState } from 'react'

export default function ConfirmActionModal({
    title,
    message,
    // color = "red",       // there's a weird bug when dynamically changing colors, the color doesn't apply on Chrome Windows and iOS Safari.
    confirmText = "OK",
    confirmAction,
    cancelText = "Cancel",
    className = "",
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <button className={className} onClick={() => setIsOpen(!isOpen)}>
                {props.children}
            </button>
            <Transition
                show={isOpen}
            >
                {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
                <div className="fixed z-10 inset-0 overflow-y-auto whitespace-pre-line">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-32 text-center sm:block sm:p-0">

                        <Transition.Child
                            enter=" ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave=" ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            {/* <!--
                                Background overlay, show/hide based on modal state.

                                Entering: "ease-out duration-300"
                                    From: "opacity-0"
                                    To: "opacity-100"
                                Leaving: "ease-in duration-200"
                                    From: "opacity-100"
                                    To: "opacity-0"
                                --> */}
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>


                            <Transition.Child
                                enter=" ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave=" ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >

                                {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                {/* <!--
                                    Modal panel, show/hide based on modal state.

                                    Entering: "ease-out duration-300"
                                        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        To: "opacity-100 translate-y-0 sm:scale-100"
                                    Leaving: "ease-in duration-200"
                                        From: "opacity-100 translate-y-0 sm:scale-100"
                                        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    --> */}
                                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className={'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10'}>
                                                {/* <div className={'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-' + color + '-100 sm:mx-0 sm:h-10 sm:w-10'}> */}

                                                {/* <!-- Heroicon name: exclamation --> */}
                                                <svg className={'h-6 w-6 text-indigo-600'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    {/* <svg className={'h-6 w-6 text-' + color + '-600'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"> */}
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                                    {title}
                                                </h3>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {message}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                                        <button onClick={confirmAction} type="button" className={'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm'}>
                                            {/* <button onClick={confirmAction} type="button" className={'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-' + color + '-600 text-base font-medium text-white hover:bg-' + color + '-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-' + color + '-500 sm:ml-3 sm:w-auto sm:text-sm'}> */}
                                            {confirmText}
                                        </button>
                                        <button onClick={() => setIsOpen(!isOpen)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                            {cancelText}
                                        </button>
                                    </div>
                                </div>
                            </Transition.Child>
                        </Transition.Child>
                    </div>
                </div>
            </Transition>
        </>
    );
}